export const percent = (numerator: number, denominator: number): string => {
  return denominator === 0 ? '0%' : `${Math.floor((numerator / denominator) * 100 * 10) / 10}%`
}

export const machineName = (value: string): string => {
  return value.replace(' &', '').replace(' ', '')
}

export const currency = (value: number): string => {
  return '$' + Math.round(value).toLocaleString()
}