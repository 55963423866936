import React from 'react'
import { percent } from 'components/Exercises/format'

// const DOES_IT = {
//   'No, revenue doesn’t matter': 'no',
//   'Yes, charities in our portfolio should be this size': 'yes'
// }

export default ({ exerciseSubmissions }: { exerciseSubmissions : any[] }) => {
  let count = {
    'yes': 0,
    'no': 0,
    'small': 0,
    'medium': 0,
    'large': 0,
  }
  exerciseSubmissions.forEach((submission) => {
    if (submission.value.does_it === 'No, revenue doesn’t matter') {
      count.no += 1
    }
    else if (submission.value.does_it === 'Yes, charities in our portfolio should be this size') {
      count.yes += 1
      if (submission.value.small) {
        count.small += 1
      }
      if (submission.value.medium) {
        count.medium += 1
      }
      if (submission.value.large) {
        count.large += 1
      }
    }
  })

  // const graphValues : number[] = exerciseSubmissions.map((submission) => { return parseInt(submission.value, 10) })
  return (
    <div className="row mt-4 text-left">
      <div className="col-12 mb-3">
        <h4><strong>{percent(count.no, exerciseSubmissions.length)} ({count.no}): </strong>No, revenue doesn't matter</h4>
      </div>
      <div className="col-12 mb-4">
        <h4><strong>{percent(count.yes, exerciseSubmissions.length)} ({count.yes}): </strong>Yes, charities in our portfolio should be these sizes:</h4>
      </div>
      <div className="col-12 mb-3 pl-5">
        <h4><strong>{percent(count.small, count.yes)} ({count.small}): </strong>Small (&lt;$1M)</h4>
      </div>
      <div className="col-12 mb-3 pl-5">
        <h4><strong>{percent(count.medium, count.yes)} ({count.medium}): </strong>Medium ($1-5M)</h4>
      </div>
      <div className="col-12 mb-3 pl-5">
        <h4><strong>{percent(count.large, count.yes)} ({count.large}): </strong>Large ($5M+)</h4>
      </div>
    </div>
  )
}