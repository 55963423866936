import React from 'react'
import ParticipantProps from 'components/Exercises/Participant/ParticipantProps'

export default ({ activeWorkshop, register }: ParticipantProps) => {
  const min: number = activeWorkshop && activeWorkshop.minCharities ? activeWorkshop.minCharities : 1
  const max: number = activeWorkshop && activeWorkshop.maxCharities ? activeWorkshop.maxCharities : 6
  let steps = []
  for (let i = min; i <= max; i++) {
    steps.push(i)
  }
  return (
    <>
      <h3>How Many Charities To Support?</h3>
      <div className="row mt-4">
        <div className="col-12">
          <input name="value" type="range" min={min} max={max} step={1} className="form-control-range" ref={register()} />
        </div>
      </div>
      <div className="row mt-2 xtext-muted">
        <div className="col-12 d-flex justify-content-between" style={{paddingLeft: `36px`, paddingRight: `36px` }}>
          {steps.map((step) => {
            return <div className={`text-center`}>{step}</div>
          })}
        </div>
      </div>
      <div className="text-center my-5">
        <input className="btn btn-primary" type="submit" value="Submit my Answers" />
        <p className="text-muted mt-2">Answers cannot be changed after submitting!</p>
      </div>
    </>
  )
}