import React, { useState, useEffect } from 'react'
import firebase from 'components/firebase'
import ParticipantProps from 'components/Exercises/Participant/ParticipantProps'
import { currency } from 'components/Exercises/format'

const SUBMISSIONS_COLLECTION: string = process.env.REACT_APP_COLLECTION_SUBMISSIONS as string

const MIN = 5
const MAX = 100
const STEP = 5

interface Charity {
  name: string,
  amount: number,
}

export default ({ activeWorkshop, register }: ParticipantProps) => {
  const [ charities, setCharities ] = useState<Charity[]>([])
  const [ numCharities, setNumCharities ] = useState<number>(activeWorkshop && activeWorkshop.finalCharities ? activeWorkshop.finalCharities : 4)
  if (!activeWorkshop || !activeWorkshop.giftAmount) {
    return <div>Number of charities or final gift amount need to be configured.</div>
  }

  const giftAmount: number = activeWorkshop.giftAmount

  if (charities.length === 0 || charities.length !== numCharities) {
    let nextCharities = []
    for (let i = 0; i < numCharities; i++) {
      nextCharities.push({ name: `Charity ${i+1}`, amount: 20 })
    }
    setCharities(nextCharities)
  }

  const updateAmount = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = parseInt(e.target.value, 10)
    if (nextValue >= MIN && nextValue <= MAX) {
      charities[index].amount = nextValue
      setCharities([...charities])
    }
  }

  useEffect(() => {
    if (activeWorkshop && !activeWorkshop.finalCharities) {
      firebase
        .firestore()
        .collection(SUBMISSIONS_COLLECTION)
        .where('workshop', '==', activeWorkshop.id)
        .get()
        .then((querySnapshot: firebase.firestore.QuerySnapshot) => {
          let nextPrevExerciseSubmissions: any[] = []
          querySnapshot.forEach(function(doc: firebase.firestore.DocumentSnapshot) {
            let submission = doc.data()
            if (submission) {
              let exerciseSubmission = submission.exercises.find((ex: { id: string, value: any }) => ex.id === '_2_02_How_Many_Charities_to_Support')
              if (exerciseSubmission) {
                nextPrevExerciseSubmissions.push(parseInt(exerciseSubmission.value, 10))
              }
            }
          })
          const total = Math.round(nextPrevExerciseSubmissions.reduce((prev, num) => prev + num, 0) / nextPrevExerciseSubmissions.length)
          if (total !== numCharities) {
            setNumCharities(total)
          }
        })
    }
  }, [activeWorkshop, numCharities])

  const totalPercent = charities.reduce((prev, next) => prev + next.amount, 0)

  return (
    <>
      <h3>How should we distribute the money?</h3>
      <p className="mt-3">The group decided that {numCharities} charities should be supported.</p>
      <p>Enter the portion that each charity should recieve according to its rank.</p>
      <p>In total, we are distributing <strong>{currency(giftAmount)}</strong>.</p>
      <div style={{position: 'relative'}}>
        {charities.map(({ name, amount }, index) => <>
          <div className="mt-4">
            <h4><strong>{name}:</strong> {currency((amount / 100) * (giftAmount))}</h4>
            <div className="d-flex">
              <div className="flex-grow-1">
                <div className="row mt-2">
                  <div className="col-12">
                    <input name={`value_${index}_range`} type="range" value={amount} onChange={(e) => updateAmount(index, e)} min={MIN} max={MAX} step={STEP} className="form-control-range" />
                  </div>
                </div>
                <div className="row mt-0">
                  <div className="col-6">{MIN}%</div>
                  <div className="col-6 text-right">{MAX}%</div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end pl-3 pr-3 mb-3" style={{width: '100%', maxWidth: '90px'}}>
                <h4 className={totalPercent === 100 ? 'text-success' : ''}><strong>{amount}%</strong></h4>
              </div>
            </div>
          </div>
          <input type="hidden" name={`value[${index}]`} value={amount} ref={register()} />
        </>)}
        <div className="d-flex border-top pt-3 pb-3 mt-4" style={{position: 'sticky', bottom: 0, background: 'white'}}>
          <div className="flex-grow-1 text-right">
            <h4>Total:</h4>
          </div>
          <div className="text-right pr-3" style={{width: '100%', maxWidth: '90px'}}>
            <h4 className={totalPercent === 100 ? 'text-success' : 'text-danger'}><strong>{totalPercent}%</strong></h4>
          </div>
        </div>
      </div>

      <div className="text-center mt-3 mb-5">
        {totalPercent > 100 && <div className="text-danger mb-2"><div className="">Too much!</div><div>These need to add up to 100%</div></div>}
        {totalPercent < 100 && <div className="text-danger mb-2"><div className="">Still some more money to distribute.</div><div>These need to add up to 100%</div></div>}
        <input disabled={totalPercent !== 100}  className="btn btn-primary" type="submit" value="Submit my Answers" />
        <p className="text-muted mt-2">Answers cannot be changed after submitting!</p>
      </div>
    </>
  )
}