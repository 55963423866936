import React from 'react'
import { Link } from "react-router-dom"
import appState from 'components/types/AppState'

export default ({ activeWorkshop }:appState) => {
  return (
    <header className="header bg-primary" role="banner" style={{ zIndex: 2 }}>
      <div className="container d-sm-flex justify-content-between align-items-center py-2 py-md-2">
        <div className="logo mb-2 mb-sm-0">
          <Link to="/">
            <img src="https://blueoceangiving.com/wp-content/themes/blueocean/src/images/logo.svg" alt="Blue Ocean Giving logo"/>
          </Link>
        </div>
        {activeWorkshop && <div className="font-weight-bold text-light">{activeWorkshop.name}</div>}
      </div>
    </header>
  )
}