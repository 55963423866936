import React, { useState, useEffect } from 'react'

import firebase from 'components/firebase'
import AppState from 'components/types/AppState'
import { ExerciseState } from 'components/types/Exercise'
import Header from 'components/Shared/Header'

const SUBMISSIONS_COLLECTION: string = process.env.REACT_APP_COLLECTION_SUBMISSIONS as string

export default (appState: AppState) => {
  const { error, loading, activeWorkshop, activeExercise, activeExerciseComponent } = appState

  const [ exerciseSubmissions, setExerciseSubmissions ] = useState<any[]>([] as any[])

  useEffect(() => {
    if (activeWorkshop && activeExercise && activeExercise.state === ExerciseState.Results) {
      firebase
        .firestore()
        .collection(SUBMISSIONS_COLLECTION)
        .where('workshop', '==', activeWorkshop.id)
        .get()
        .then((querySnapshot: firebase.firestore.QuerySnapshot) => {
          let exerciseSubmissions: any[] = []
          querySnapshot.forEach(function(doc: firebase.firestore.DocumentSnapshot) {
            let submission = doc.data()
            if (submission) {
              let exerciseSubmission = submission.exercises.find((ex: { id: string, value: any }) => {
                return ex.id === activeExercise.id}
              )
              if (exerciseSubmission) {
                exerciseSubmissions.push(exerciseSubmission)
              }
            }
          })
          setExerciseSubmissions([...exerciseSubmissions])
        })
    }
  }, [activeWorkshop, activeExercise, exerciseSubmissions.length])

  let final = null
  if (activeExerciseComponent) {
    const Presenter = activeExerciseComponent.presenter as Function
    final = <Presenter {...appState} Result={activeExerciseComponent.result} exerciseSubmissions={exerciseSubmissions} />
  }
  else {
    final = <div>No active exercises.</div>
  }
  return (
    <div>
      <Header {...appState} />
      {error && <div>An error occurred: {error.message}</div>}
      {loading && <div>Loading...</div>}
      <div className="container py-3 d-flex justify-content-center align-items-center" style={{ minHeight: 'calc(100vh - 80px)' }}>
        {final}
      </div>
    </div>
  )
}
