import React from 'react'
import AppState from 'components/types/AppState'
import { ExerciseState } from 'components/types/Exercise'

export default ({ activeExercise, activeWorkshop, activeExerciseComponent, Result, exerciseSubmissions }: AppState & { exerciseSubmissions : any[], Result : typeof React.Component }) => {
	const showResults = activeExercise && (activeExercise.state === ExerciseState.Results) && activeExerciseComponent && exerciseSubmissions.length > 0 && activeExerciseComponent.id === exerciseSubmissions[0].id && Result
	return (
		<div className="col-md-12 col-lg-12 col-xl-12 text-md-center">
		  <h1 className="mb-2">Choose Sectors</h1>
		  {!showResults && <>
		  	<p>What sectors or charitable themes do you want to support?</p>
		  </>}
      {showResults && <Result exerciseSubmissions={exerciseSubmissions} />}
		</div>
	)
}