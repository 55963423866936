import React from 'react'
import ParticipantProps from 'components/Exercises/Participant/ParticipantProps'

export default ({ activeWorkshop, register, exerciseComponent }: ParticipantProps) => {
  return (
    <>
      <h3>Approximately how many charities should be in our portfolio? (Choose one)</h3>
      <div className="row mt-4">
        <div className="col-6">
          <div className="form-group form-check">
            <input type="radio" className="form-check-input" name="value" value="1 &ndash; 3" id="local" ref={register()} />
            <label className="form-check-label" htmlFor="local">1 &ndash; 3</label>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group form-check">
            <input type="radio" className="form-check-input" name="value" value="4 &ndash; 7" id="provincial" ref={register()} />
            <label className="form-check-label" htmlFor="provincial">4 &ndash; 7</label>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group form-check">
            <input type="radio" className="form-check-input" name="value" value="8 &ndash; 12" id="national" ref={register()} />
            <label className="form-check-label" htmlFor="national">8 &ndash; 12</label>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group form-check">
            <input type="radio" className="form-check-input" name="value" value="12+" id="international" ref={register()} />
            <label className="form-check-label" htmlFor="international">12+</label>
          </div>
        </div>
      </div>
      <div className="text-center my-5">
        <input className="btn btn-primary" type="submit" value="Submit my Answers" />
        <p className="text-muted mt-2">Answers cannot be changed after submitting!</p>
      </div>
    </>
  )
}
