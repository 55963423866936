import React from 'react'
import Range from 'components/Exercises/Result/Range'

export default ({ exerciseSubmissions }: { exerciseSubmissions : any[] }) => {
  const graphValues : number[] = exerciseSubmissions.map((submission) => { return parseInt(submission.value, 10) })
  return (
    <div className="pt-2">
      <Range values={graphValues} />
      <div className="row pt-2 xtext-muted">
        <div className="col-6 text-left">Specific Needs</div>
        <div className="col-6 text-right">Systems Change</div>
      </div>
    </div>
  )
}