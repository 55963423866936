import React from 'react'
import Expando from 'components/Exercises/Participant/Expando'
import Causes from 'components/Exercises/Causes'

export default () => {
  return (
    <>
      <p>Discussion: what specific causes do you want to support?</p>

      {Causes.map(({ name, groups }) => <>
        <h3 className="mt-4">{name}</h3>
        <Expando>
          {groups.map(({ name, causes }) => <>
            <h4 className=" mb-1">{name} includes:</h4>
            <p className="mb-3">{causes.map(({ name }) => name).join(', ')}.</p>
          </>)}
        </Expando>
      </>)}
    </>
  )
}