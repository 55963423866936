import React, { useState } from 'react'
import ParticipantProps from 'components/Exercises/Participant/ParticipantProps'
import Charity from 'components/types/Charity'

import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

interface FormState extends Charity {
  fakeName: string
  open: boolean
}

export default ({ activeWorkshop, register, watch, exerciseComponent }: ParticipantProps) => {
  const [ form, setForm ] = useState<FormState[]>([])
  const isFinal = exerciseComponent.id === '_2_08_Final_Choices_Presenter'

  if (activeWorkshop && form.length !== activeWorkshop.charities.length) {
    setForm(activeWorkshop.charities.map((charity, index) => { return {
      ...charity,
      fakeName: `Charity ${index+1}`,
      open: false,
    }}))
  }

  const toggleOpen = (index: number): void => {
    form[index].open = !form[index].open
    setForm([...form])
  }

  const move = (index: number, direction: number): void => {
    const from = index
    const to = from + direction

    if (to < 0) { return }
    if (to >= form.length) { return }

    // Move array around: https://stackoverflow.com/a/21071454
    const target = form[from]
    const increment = to < from ? -1 : 1

    for (let k = from; k !== to; k += increment) {
      form[k] = form[k + increment]
    }
    form[to] = target
    setForm([...form])
  }

  return (
    <>
      <h1>Prioritize Charities</h1>

      {form.map(({ name, fakeName, description, revenue, causes, open }, index) => <>
        <div className="card my-2 border-bottom xpb-2" style={{position: 'relative'}}>
          <input type="hidden" name={`value[${index}]`} value={name} ref={register()} />
          <div className="card-header py-2 pr-2 pl-3" style={{position: 'sticky', top: '90px', background: '#efefef'}}>
            <div className="xmt-4 xmb-1 d-flex justify-content-between align-items-center">
              <div className="xmt-1 flex-shrink-1 pr-2">
                <h4>{index+1}: {isFinal ? name : fakeName}</h4>
                <div onClick={() => toggleOpen(index)} className="btn btn-primary btn-sm xml-1 mt-2">{open ? 'Less Info' : 'More Info'}</div>
              </div>
              <div>
                <div onClick={() => move(index, -1)} className="d-block btn btn-primary xbtn-sm xmt-1 chevron-up mb-2">Down</div>
                <div onClick={() => move(index, 1)} className="d-block btn btn-primary xbtn-sm xmt-1 chevron-down ">Up</div>
              </div>
            </div>
          </div>
          <SlideDown className="blueocean-dropdown-slidedown">
            {open && <>
              <div className="card-body pl-3 pr-3 py-2">
                <p className="hyphens">{description}</p>
                <div>
                  <strong>Causes:</strong>
                  <p>{causes}</p>
                </div>
                <div>
                  <strong>Revenue:</strong> {revenue}
                </div>
              </div>
            </>}
          </SlideDown>
        </div>
      </>)}

      <div className="text-center my-5">
        <input className="btn btn-primary" type="submit" value="Submit my Answers" />
        <p className="text-muted mt-2">Answers cannot be changed after submitting!</p>
      </div>
    </>
  )
}