const causes = [
  {
    name: 'Vibrant Communities',
    description: 'Charities in this sector support sports & recreation, arts & culture, sustainable foods systems, adult education, and youth.',
    groups: [
      {
        name: 'Arts & Culture',
        causes: [
          { name: 'Theatre' },
          { name: 'Ballet' },
          { name: 'Dance Symphony' },
          { name: 'Museums' },
          { name: 'Indigenous Performances' },
          { name: 'Arts Education' },
          { name: 'Literature' },
          { name: 'Creative Writing' },
          { name: 'Music' },
        ]
      },
      {
        name: 'Sports & Recreation',
        causes: [
          { name: 'Access For All' },
          { name: 'Wellness Through Recreation' },
          { name: 'Amateur Sports' },
          { name: 'Facilities And Services' },
          { name: 'Power Of Sport' },
        ]
      },
      {
        name: 'Sustainable Food Systems',
        causes: [
          { name: 'Local Food' },
          { name: 'Food Security' },
          { name: 'Labelling' },
          { name: 'Sustainable Seafood' },
          { name: 'GMOs' },
          { name: 'Slow Food' },
          { name: 'Organic Food' },
          { name: 'Certification Systems' },
          { name: 'Food Access' },
          { name: 'Indigenous Food Systems' },
        ]
      },
      {
        name: 'Adult Education',
        causes: [
          { name: 'Literacy' },
          { name: 'Languages' },
          { name: 'Job Training' },
          { name: 'Mentorship Programs' },
          { name: 'Coaching' },
          { name: 'Retreat Centres' },
          { name: 'Access To Education' },
          { name: 'Leadership Training' },
          { name: 'Scholarships And Bursaries' },
        ]
      },
      {
        name: 'Youth',
        causes: [
          { name: 'Empowerment' },
          { name: 'Leadership' },
          { name: 'Youth At Risk' },
          { name: 'Activism' },
          { name: 'Outdoor Education' },
          { name: 'Creative Development' },
          { name: 'Adult Preparation' },
          { name: 'Social & Emotional Intelligence' },
          { name: 'Mentorship' },
        ]
      },
    ]
  },
  {
    name: 'Animals & the Environment',
    description: 'Charities in this sector support animal welfare, land & ocean conservation, climate change, clean energy, fresh water health, and clean transportation.',
    groups: [
      {
        name: 'Animal Welfare',
        causes: [
          { name: 'Humane Treatment' },
          { name: 'Animal Testing' },
          { name: 'Shelters' },
          { name: 'Justice' },
          { name: 'Rescues' },
          { name: 'Animal Rights' },
          { name: 'Anti-Fur' },
          { name: 'Captivity' },
          { name: 'Senior Animal Treatment' },
          { name: 'Wildlife Protection' },
          { name: 'Sanctuaries' },
        ]
      },
      {
        name: 'Land & Ocean Conservation',
        causes: [
          { name: 'Parks And Protected Areas' },
          { name: 'Connecting Habitat' },
          { name: 'Conservation Mapping' },
          { name: 'Habitat Restoration' },
          { name: 'Biodiversity' },
          { name: 'Land Trusts' },
          { name: 'Marine Protected Areas' },
          { name: 'Watershed Preservation' },
        ]
      },
      {
        name: 'Climate Change & Clean Energy',
        causes: [
          { name: 'Carbon Reduction' },
          { name: 'Energy Efficiency' },
          { name: 'Offsets' },
          { name: 'Renewable Energy' },
          { name: 'Policy Change' },
          { name: 'Severe Weather Events' },
          { name: 'Fossil Fuel Divestment' },
          { name: 'Adaptations' },
        ]
      },
      {
        name: 'Fresh Water Health',
        causes: [
          { name: 'Freshwater Protection' },
          { name: 'Right To Clean Water' },
          { name: 'Pollution' },
          { name: 'Law & Policy' },
          { name: 'Overuse Of Water' },
          { name: 'Invasive Species' },
          { name: 'Freshwater Data' },
          { name: 'Drinking Water' },
          { name: 'Watershed Governance' },
          { name: 'Dams' },
          { name: 'Droughts And Flooding' },
          { name: 'Habitat Restoration' },
        ]
      },
      {
        name: 'Clean Transportation',
        causes: [
          { name: 'Public transit' },
          { name: 'Rail systems' },
          { name: 'Bike infrastructure' },
          { name: 'Car sharing' },
          { name: 'Public policy advocacy' },
          { name: 'Changing behaviours' },
          { name: 'Electric cars' },
          { name: 'Bike sharing' },
        ]
      },
    ]
  },
  {
    name: 'Health & Social Services',
    description: 'Charities in this sector support poverty, public health, and addiction issues.',
    groups: [
      {
        name: 'Poverty',
        causes: [
          { name: 'Homelessness' },
          { name: 'Low Income Housing' },
          { name: 'Gentrification' },
          { name: 'Legal Support' },
          { name: 'Healthy Food Access' },
          { name: 'Health Care' },
          { name: 'Education' },
          { name: 'Income Inequality' },
          { name: 'Livable Wage' },
          { name: 'Economic Development' },
          { name: 'Equal Access' },
          { name: 'Homeless Rights' },
        ]
      },
      {
        name: 'Public Health',
        causes: [
          { name: 'Access To Health Care' },
          { name: 'Obesity' },
          { name: 'Preventative Care' },
          { name: 'Disease Control' },
          { name: 'Hospitals' },
          { name: 'Mental Health' },
          { name: 'Special Needs' },
          { name: 'Chronic Diseases' },
          { name: 'Wellness' },
        ]
      },
      {
        name: 'Addiction Issues',
        causes: [
          { name: 'Harm Reduction' },
          { name: 'Rehabilitation Centres' },
          { name: 'Safe Injection Sites' },
          { name: 'Drug & Alcohol Abuse' },
          { name: '12 Step Programs' },
          { name: 'Overmedicating Children' },
          { name: 'Legal Support' },
        ]
      },
    ]
  },
  {
    name: 'People & Society',
    description: 'Charities in this sector support Indigenous peoples, LGBTQ+ rights, anti-racism, and refugees.',
    groups: [
      {
        name: 'Indigenous Peoples',
        causes: [
          { name: 'Reconciliation' },
          { name: 'Rights And Title' },
          { name: 'Residential School Survivors' },
          { name: 'Youth Engagement' },
          { name: 'Indigenous Guardians' },
          { name: 'Land-Based Learning' },
          { name: 'Indigenous Knowledge' },
          { name: 'Housing' },
          { name: 'Schools & Education' },
          { name: 'Indigenous Laws' },
          { name: 'Support For First Nation Governments' },
          { name: 'Decolonization' },
          { name: 'Language' },
          { name: 'Cultural Survival' },
          { name: 'Land And Treaty Rights' },
        ]
      },
      {
        name: 'LGBTQ+ Rights',
        causes: [
          { name: 'Awareness' },
          { name: 'Equal Benefits' },
          { name: 'Addressing Homophobia' },
          { name: 'Youth Support' },
          { name: 'Police Accountability' },
          { name: 'Access To Adoption' },
          { name: 'Pride' },
          { name: 'Anti-Bullying' },
        ]
      },
      {
        name: 'Anti-Racism',
        causes: [
          { name: 'Bigotry' },
          { name: 'Racial Marginalization' },
          { name: 'Presumed Entitlement' },
          { name: 'White Privilege' },
          { name: 'Ethnic Conflict' },
          { name: 'Individual Rights' },
          { name: 'White Supremacy' },
          { name: 'Racial Profiling' },
          { name: 'Decolonization' },
          { name: 'Policy Accountability' },
          { name: 'Media Coverage' },
          { name: 'Equal Opportunity' },
          { name: 'Systemic Racism' },
          { name: 'Truth & Reconciliation' },
        ]
      },
      {
        name: 'Refugees',
        causes: [
          { name: 'Settlement Services' },
          { name: 'Forced Migration' },
          { name: 'Unaccompanied Refugee Minors' },
          { name: 'Refugee Policies' },
          { name: 'Trauma Counseling' },
          { name: 'Detention Policies' },
          { name: 'Advocacy' },
          { name: 'Emergency Aid' },
        ]
      },
    ]
  },
]

export default causes;