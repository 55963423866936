import React from 'react'
import Expando from 'components/Exercises/Participant/Expando'
import ParticipantProps from 'components/Exercises/Participant/ParticipantProps'

export default ({ activeWorkshop, register }: ParticipantProps) => {
  return (
    <>
      <h3>Do you prefer charities that address specific needs, or those that work to change larger systems?</h3>
      <Expando>
        <p>For many issues (e.g. poverty, population health, pollution) solutions are required at two levels: </p>
        <p>1) Changing the system that created the problem in the first place.</p>
        <p>2) Help for the specific people or places that are impacted.</p>
        <p>Many charities focus their efforts somewhere along this continuum.</p>
      </Expando>
      <div className="row mt-4">
        <div className="col-12">
          <input name="value" type="range" className="form-control-range" ref={register()} />
        </div>
      </div>
      <div className="row mt-2 xtext-muted">
        <div className="col-6">Specific Needs</div>
        <div className="col-6 text-right">Change Systems</div>
      </div>
      <div className="text-center my-5">
        <input className="btn btn-primary" type="submit" value="Submit my Answers" />
        <p className="text-muted mt-2">Answers cannot be changed after submitting!</p>
      </div>
    </>
  )
}