// import React from 'react'
import ExerciseComponent from 'components/types/ExerciseComponent'

// Webpack doesn't like to lead with a number, so add an underscore.
import Checkbox_Result from 'components/Exercises/Result/Checkbox'
import Radio_Result from 'components/Exercises/Result/Radio'

import Welcome_Presenter from 'components/Exercises/Presenter/Welcome'
import Welcome_Participant from 'components/Exercises/Participant/Welcome'

import Thank_You_Presenter from 'components/Exercises/Presenter/Thank_You'
import Thank_You_Participant from 'components/Exercises/Participant/Thank_You'

import _1_02_System_Change_Presenter from 'components/Exercises/Presenter/_1_02_System_Change'
import _1_02_System_Change_Participant from 'components/Exercises/Participant/_1_02_System_Change'
import _1_02_System_Change_Result from 'components/Exercises/Result/_1_02_System_Change'

import _1_03_Scale_of_Impact_Presenter from 'components/Exercises/Presenter/_1_03_Scale_of_Impact'
import _1_03_Scale_of_Impact_Participant from 'components/Exercises/Participant/_1_03_Scale_of_Impact'

import _1_04_Number_of_Charities_Presenter from 'components/Exercises/Presenter/_1_04_Number_of_Charities'
import _1_04_Number_of_Charities_Participant from 'components/Exercises/Participant/_1_04_Number_of_Charities'

import _1_05_Overhead_Presenter from 'components/Exercises/Presenter/_1_05_Overhead'
import _1_05_Overhead_Participant from 'components/Exercises/Participant/_1_05_Overhead'
import _1_05_Overhead_Result from 'components/Exercises/Result/_1_05_Overhead'

import _1_06_Variety_of_charities_Presenter from 'components/Exercises/Presenter/_1_06_Variety_of_charities'
import _1_06_Variety_of_charities_Participant from 'components/Exercises/Participant/_1_06_Variety_of_charities'
import _1_06_Variety_of_charities_Result from 'components/Exercises/Result/_1_06_Variety_of_charities'

import _1_07_Multi_year_commitment_Presenter from 'components/Exercises/Presenter/_1_07_Multi_year_commitment'
import _1_07_Multi_year_commitment_Participant from 'components/Exercises/Participant/_1_07_Multi_year_commitment'
import _1_07_Multi_year_commitment_Result from 'components/Exercises/Result/_1_07_Multi_year_commitment'

import _1_08_Does_Revenue_Matter_Presenter from 'components/Exercises/Presenter/_1_08_Does_Revenue_Matter'
import _1_08_Does_Revenue_Matter_Participant from 'components/Exercises/Participant/_1_08_Does_Revenue_Matter'
import _1_08_Does_Revenue_Matter_Result from 'components/Exercises/Result/_1_08_Does_Revenue_Matter'

import _1_09_First_Impressions_Choose_Sectors_Presenter from 'components/Exercises/Presenter/_1_09_First_Impressions_Choose_Sectors'
import _1_09_11_Choose_Sector from 'components/Exercises/Participant/_1_09_11_Choose_Sector'

import _1_10_Discuss_First_Impression_Sector_Choices_Presenter from 'components/Exercises/Presenter/_1_10_Discuss_First_Impression_Sector_Choices'
import _1_10_Discuss_First_Impression_Sector_Choices_Participant from 'components/Exercises/Participant/_1_10_Discuss_First_Impression_Sector_Choices'

import _1_11_Confirm_Sector_Choices_Presenter from 'components/Exercises/Presenter/_1_11_Confirm_Sector_Choices'

import _1_12_Discuss_Specific_Causes_Presenter from 'components/Exercises/Presenter/_1_12_Discuss_Specific_Causes'
import _1_12_Discuss_Specific_Causes_Participant from 'components/Exercises/Participant/_1_12_Discuss_Specific_Causes'

import _1_13_14_Choose_Causes_Presenter from 'components/Exercises/Presenter/_1_13_14_Choose_Causes'
import _1_13_14_Choose_Causes_Participant from 'components/Exercises/Participant/_1_13_14_Choose_Causes'
import Sorted_Result from 'components/Exercises/Result/Sorted'

import _2_02_How_Many_Charities_to_Support_Presenter from 'components/Exercises/Presenter/_2_02_How_Many_Charities_to_Support'
import _2_02_How_Many_Charities_to_Support_Participant from 'components/Exercises/Participant/_2_02_How_Many_Charities_to_Support'
import _2_02_How_Many_Charities_to_Support_Result from 'components/Exercises/Result/_2_02_How_Many_Charities_to_Support'

import _2_03_Distributing_Our_Gift_Presenter from 'components/Exercises/Presenter/_2_03_Distributing_Our_Gift'
import _2_03_Distributing_Our_Gift_Participant from 'components/Exercises/Participant/_2_03_Distributing_Our_Gift'
import _2_03_Distributing_Our_Gift_Result from 'components/Exercises/Result/_2_03_Distributing_Our_Gift'

import _2_04_Charity_Choices_by_Description_Presenter from 'components/Exercises/Presenter/_2_04_Charity_Choices_by_Description'
import _2_04_Charity_Choices_by_Description_Participant from 'components/Exercises/Participant/_2_04_Charity_Choices_by_Description'
import _2_04_Charity_Choices_by_Description_Result from 'components/Exercises/Result/_2_04_Charity_Choices_by_Description'

import _2_05_Reveal_Names_Presenter from 'components/Exercises/Presenter/_2_05_Reveal_Names'
import _2_05_Reveal_Names_Participant from 'components/Exercises/Participant/_2_05_Reveal_Names'

import _2_06_Small_Group_Discussion_of_Choices_Presenter from 'components/Exercises/Presenter/_2_06_Small_Group_Discussion_of_Choices'
import _2_06_07_Small_Group_Discussion_of_Choices_Participant from 'components/Exercises/Participant/_2_06_07_Small_Group_Discussion_of_Choices'

import _2_07_Full_Group_Discussion_of_Choices_Presenter from 'components/Exercises/Presenter/_2_07_Full_Group_Discussion_of_Choices'

import _2_08_Final_Choices_Presenter from 'components/Exercises/Presenter/_2_08_Final_Choices'
import _2_08_Final_Choices_Result from 'components/Exercises/Result/_2_08_Final_Choices'

const index: ExerciseComponent[] = [
  {
    'id': '_01_01_Welcome',
    'name': 'Welcome / Participants Join',
    'presenter': Welcome_Presenter,
    'participant': Welcome_Participant,
    'session': 1,
    'answerable': false,
  },
  {
    'id': '_1_02_System_Change',
    'name': 'System change vs immediate needs',
    'presenter': _1_02_System_Change_Presenter,
    'participant': _1_02_System_Change_Participant,
    'result': _1_02_System_Change_Result,
    'session': 1,
    'answerable': true,
  },
  {
    'id': '_1_03_Scale_of_Impact',
    'name': 'Scale of Impact',
    'presenter': _1_03_Scale_of_Impact_Presenter,
    'participant': _1_03_Scale_of_Impact_Participant,
    'result': Checkbox_Result,
    'session': 1,
    'answerable': true,
  },
  {
    'id': '_1_04_Number_of_Charities',
    'name': 'Number of Charities',
    'presenter': _1_04_Number_of_Charities_Presenter,
    'participant': _1_04_Number_of_Charities_Participant,
    'result': Radio_Result,
    'session': 1,
    'answerable': true,
  },
  {
    'id': '_1_05_Overhead',
    'name': 'Overhead',
    'presenter': _1_05_Overhead_Presenter,
    'participant': _1_05_Overhead_Participant,
    'result': _1_05_Overhead_Result,
    'session': 1,
    'answerable': true,
  },
  {
    'id': '_1_06_Variety_of_charities',
    'name': 'Variety of Charities',
    'presenter': _1_06_Variety_of_charities_Presenter,
    'participant': _1_06_Variety_of_charities_Participant,
    'result': _1_06_Variety_of_charities_Result,
    'session': 1,
    'answerable': true,
  },
  {
    'id': '_1_07_Multi_year_commitment',
    'name': 'Multi-year Commitment',
    'presenter': _1_07_Multi_year_commitment_Presenter,
    'participant': _1_07_Multi_year_commitment_Participant,
    'result': _1_07_Multi_year_commitment_Result,
    'session': 1,
    'answerable': true,
  },
  {
    'id': '_1_08_Does_Revenue_Matter',
    'name': 'Does Revenue Matter',
    'presenter': _1_08_Does_Revenue_Matter_Presenter,
    'participant': _1_08_Does_Revenue_Matter_Participant,
    'result': _1_08_Does_Revenue_Matter_Result,
    'session': 1,
    'answerable': true,
  },
  {
    'id': '_1_09_First_Impressions_Choose_Sectors',
    'name': 'First Impressions: Choose Sectors',
    'presenter': _1_09_First_Impressions_Choose_Sectors_Presenter,
    'participant': _1_09_11_Choose_Sector, // Exact same as step _1_11
    'result': Checkbox_Result,
    'session': 1,
    'answerable': true,
  },
  {
    'id': '_1_10_Discuss_Sector_Choices',
    'name': 'Discuss Sector Choices',
    'presenter': _1_10_Discuss_First_Impression_Sector_Choices_Presenter,
    'participant': _1_10_Discuss_First_Impression_Sector_Choices_Participant,
    'result': Checkbox_Result,
    'session': 1,
    'answerable': false,
  },
  {
    'id': '_1_11_Confirm_Sector_Choices',
    'name': 'Confirm Sector Choices',
    'presenter': _1_11_Confirm_Sector_Choices_Presenter,
    'participant': _1_09_11_Choose_Sector, // Exact same as step _1_09
    'result': Checkbox_Result,
    'session': 1,
    'answerable': true,
  },
  {
    'id': '_1_12_Discuss_Specific_Causes',
    'name': 'Discuss Causes',
    'presenter': _1_12_Discuss_Specific_Causes_Presenter,
    'participant': _1_12_Discuss_Specific_Causes_Participant,
    'result': Checkbox_Result,
    'session': 1,
    'answerable': false,
  },
  {
    'id': '_1_13_Choose_Causes',
    'name': 'Choose Causes',
    'presenter': _1_13_14_Choose_Causes_Presenter,
    'participant': _1_13_14_Choose_Causes_Participant,
    'result': Sorted_Result,
    'session': 1,
    'answerable': true,
  },
  {
    'id': '_1_14_Confirm_Choose_Causes',
    'name': 'Confirm Causes',
    'presenter': _1_13_14_Choose_Causes_Presenter,
    'participant': _1_13_14_Choose_Causes_Participant,
    'result': Sorted_Result,
    'session': 1,
    'answerable': true,
  },
  {
    'id': '_1_15_Thank_You',
    'name': 'Thank You',
    'presenter': Thank_You_Presenter,
    'participant': Thank_You_Participant,
    'session': 1,
    'answerable': false,
  },
  {
    'id': '_2-01-Welcome',
    'name': 'Participants Join',
    'presenter': Welcome_Presenter,
    'participant': Welcome_Participant,
    'session': 2,
    'answerable': false,
  },
  {
    'id': '_2_02_How_Many_Charities_to_Support',
    'name': 'How Many Charities to Support?',
    'presenter': _2_02_How_Many_Charities_to_Support_Presenter,
    'participant': _2_02_How_Many_Charities_to_Support_Participant,
    'result': _2_02_How_Many_Charities_to_Support_Result,
    'session': 2,
    'answerable': true,
  },
  {
    'id': '_2_03_Distributing_Our_Gift',
    'name': 'Distributing Our Gift',
    'presenter': _2_03_Distributing_Our_Gift_Presenter,
    'participant': _2_03_Distributing_Our_Gift_Participant,
    'result': _2_03_Distributing_Our_Gift_Result,
    'session': 2,
    'answerable': true,
  },
  {
    'id': '_2_04_Charity_Choices_by_Description',
    'name': 'Charity Choices by Description',
    'presenter': _2_04_Charity_Choices_by_Description_Presenter,
    'participant': _2_04_Charity_Choices_by_Description_Participant,
    'result': _2_04_Charity_Choices_by_Description_Result,
    'session': 2,
    'answerable': true,
  },
  {
    'id': '_2_05_Reveal_Names',
    'name': 'Reveal Names',
    'presenter': _2_05_Reveal_Names_Presenter,
    'participant': _2_05_Reveal_Names_Participant,
    'result': _2_04_Charity_Choices_by_Description_Result,
    'session': 2,
    'answerable': false,
  },
  {
    'id': '_2_06_Small_Group_Discussion_of_Choices',
    'name': 'Small Group Discussion',
    'presenter': _2_06_Small_Group_Discussion_of_Choices_Presenter,
    'participant': _2_06_07_Small_Group_Discussion_of_Choices_Participant,
    'result': _2_04_Charity_Choices_by_Description_Result,
    'session': 2,
    'answerable': false,
  },
  {
    'id': '_2_07_Full_Group_Discussion_of_Choices',
    'name': 'Full Group Discussion',
    'presenter': _2_07_Full_Group_Discussion_of_Choices_Presenter,
    'participant': _2_06_07_Small_Group_Discussion_of_Choices_Participant,
    'result': _2_04_Charity_Choices_by_Description_Result,
    'session': 2,
    'answerable': false,
  },
  {
    'id': '_2_08_Final_Choices_Presenter',
    'name': 'Final Choices',
    'presenter': _2_08_Final_Choices_Presenter,
    'participant': _2_04_Charity_Choices_by_Description_Participant,
    'result': _2_08_Final_Choices_Result,
    'session': 2,
    'answerable': true,
  },
  {
    'id': '_2_09_Thank_You',
    'name': 'Thank You',
    'presenter': Thank_You_Presenter,
    'participant': Thank_You_Participant,
    'session': 2,
    'answerable': false,
  },
]

export default index