import React from 'react'
import AppState from 'components/types/AppState'
import { ExerciseState } from 'components/types/Exercise'

export default ({ activeExercise, activeWorkshop, activeExerciseComponent, Result, exerciseSubmissions }: AppState & { exerciseSubmissions : any[], Result : typeof React.Component }) => {
	const showResults = activeExercise && (activeExercise.state === ExerciseState.Results) && activeExerciseComponent && exerciseSubmissions.length > 0 && activeExerciseComponent.id === exerciseSubmissions[0].id && Result
	const min: number = activeWorkshop && activeWorkshop.minCharities ? activeWorkshop.minCharities : 1
  const max: number = activeWorkshop && activeWorkshop.maxCharities ? activeWorkshop.maxCharities : 6
  let steps = []
  for (let i = min; i <= max; i++) {
    steps.push(i)
  }
	return (
		<div className="col-md-10 col-lg-8 col-xl-6 text-md-center">
		  <h1 className="mb-2">Number of Charities</h1>
		  {!showResults && <>
		  	<p>How many charities do you think we should support?</p>
		  	<div className="row mt-4">
	        <div className="col-12">
	          <input name="value" type="range" min={min} max={max} step={1} className="form-control-range" />
	        </div>
	      </div>
	      <div className="row mt-2 xtext-muted">
	        <div className="col-12 d-flex justify-content-between" style={{paddingLeft: `36px`, paddingRight: `36px` }}>
	          {steps.map((step) => {
	            return <div className={`text-center`}>{step}</div>
	          })}
	        </div>
	      </div>
		  </>}
      {showResults && <Result exerciseSubmissions={exerciseSubmissions} activeExercise={activeExercise} activeWorkshop={activeWorkshop} />}
		</div>
	)
}