import React from 'react'
import ParticipantProps from 'components/Exercises/Participant/ParticipantProps'

export default ({ activeWorkshop, register, exerciseComponent }: ParticipantProps) => {
  return (
    <>
      <h3>What scale of impact should charities in our portfolio try to make? (Choose any number)</h3>
      <div className="row mt-4">
        <div className="col-6">
          <div className="form-group form-check">
            <input type="checkbox" className="form-check-input" name="value[Local]" id="local" ref={register()} />
            <label className="form-check-label" htmlFor="local">Local</label>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group form-check">
            <input type="checkbox" className="form-check-input" name="value[Provincial]" id="provincial" ref={register()} />
            <label className="form-check-label" htmlFor="provincial">Provincial</label>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group form-check">
            <input type="checkbox" className="form-check-input" name="value[National]" id="national" ref={register()} />
            <label className="form-check-label" htmlFor="national">National</label>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group form-check">
            <input type="checkbox" className="form-check-input" name="value[International]" id="international" ref={register()} />
            <label className="form-check-label" htmlFor="international">International</label>
          </div>
        </div>
      </div>
      <div className="text-center my-5">
        <input className="btn btn-primary" type="submit" value="Submit my Answers" />
        <p className="text-muted mt-2">Answers cannot be changed after submitting!</p>
      </div>
    </>
  )
}