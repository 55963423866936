import React, { useState } from 'react'
import ParticipantProps from 'components/Exercises/Participant/ParticipantProps'
import Charity from 'components/types/Charity'

import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

interface FormState extends Charity {
  fakeName: string
  open: boolean
}

export default ({ activeWorkshop, register, watch, exerciseComponent, submission }: ParticipantProps) => {
  const [ form, setForm ] = useState<FormState[]>([])

  let prevExercise: { id: string, value: any } | undefined
  if (submission) {
    prevExercise = submission.exercises.find((exercise) => exercise.id === '_2_04_Charity_Choices_by_Description')
  }
  if (!prevExercise) {
    return <div>No previous exercise found.</div>
  }

  if (activeWorkshop && form.length !== activeWorkshop.charities.length) {
    const charities = activeWorkshop.charities.map((charity, index) => { return {
      ...charity,
      fakeName: `Charity ${index+1}`,
      open: true,
    }})
    setForm(prevExercise.value.map((value: string) => {
      return charities.find((charity) => charity.name === value)
    }))
  }

  const toggleOpen = (index: number): void => {
    form[index].open = !form[index].open
    setForm([...form])
  }

  return (
    <>
      <h1 className="mb-3">Discussion: what charities do you want to support?</h1>

      {form.map(({ name, fakeName, description, revenue, causes, open }, index) => <>
        <div className="card my-2 border-bottom xpb-2" style={{position: 'relative'}}>
          <div className="card-header py-2 pr-2 pl-3" style={{position: 'sticky', top: '90px', background: '#efefef'}}>
            <div className="xmt-4 xmb-1 d-flex justify-content-between align-items-center">
              <div className="xmt-1 flex-shrink-1 pr-2">
                <h4>{index+1}: {name} ({fakeName})</h4>
                <div onClick={() => toggleOpen(index)} className="btn btn-primary btn-sm xml-1 mt-2">{open ? 'Less Info' : 'More Info'}</div>
              </div>
            </div>
          </div>
          <SlideDown className="blueocean-dropdown-slidedown">
            {open && <>
              <div className="card-body pl-3 pr-3 py-2">
                <p className="hyphens">{description}</p>
                <div>
                  <strong>Causes:</strong>
                  <p>{causes}</p>
                </div>
                <div>
                  <strong>Revenue:</strong> {revenue}
                </div>
              </div>
            </>}
          </SlideDown>
        </div>
      </>)}
    </>
  )
}
