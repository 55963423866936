import React from 'react'
import AppState from 'components/types/AppState'
import { ExerciseState } from 'components/types/Exercise'

export default ({ activeExercise, activeWorkshop, activeExerciseComponent, Result, exerciseSubmissions }: AppState & { exerciseSubmissions : any[], Result : typeof React.Component }) => {
  const showResults = activeExercise && (activeExercise.state === ExerciseState.Results) && activeExerciseComponent && exerciseSubmissions.length > 0 && activeExerciseComponent.id === exerciseSubmissions[0].id && Result
  return (
    <div className="col-md-10 col-lg-8 col-xl-6 text-md-center">
      <h1 className="mb-2">System Change vs Immediate Needs</h1>
      <p>Do you prefer charities that address specific needs, or those that try and change larger systems?</p>
      {!showResults && <>
        <div className="row mt-4">
          <div className="col-12">
            <input name="value" type="range" className="form-control-range" />
          </div>
        </div>
        <div className="row mt-2 xtext-muted">
          <div className="col-6 text-left">Specific Needs</div>
          <div className="col-6 text-right">Change Systems</div>
        </div>
      </>}
      {showResults && <Result exerciseSubmissions={exerciseSubmissions} />}
    </div>
  )
}