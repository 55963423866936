import React from 'react'
import AppState from 'components/types/AppState'
import { ExerciseState } from 'components/types/Exercise'

export default ({ activeExercise, activeWorkshop, activeExerciseComponent, Result, exerciseSubmissions }: AppState & { exerciseSubmissions : any[], Result : typeof React.Component }) => {
	const showResults = activeExercise && (activeExercise.state === ExerciseState.Results) && activeExerciseComponent && exerciseSubmissions.length > 0 && activeExerciseComponent.id === exerciseSubmissions[0].id && Result
	return (
		<div className="col-md-10 col-lg-8 col-xl-6 text-md-center">
		  <h1 className="mb-2">Choose Causes</h1>
		  {!showResults && <>
		  	<p>Select up to 5 specific causes that you'd like to support.</p>
			 	<p>Use the arrow buttons to place your choices in order of preference. Causes near the top of your list will be weighted more than lower ones.</p>
		  </>}
    	{showResults && <Result exerciseSubmissions={exerciseSubmissions} />}
		</div>
	)
}