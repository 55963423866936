import React from 'react'
import ParticipantProps from 'components/Exercises/Participant/ParticipantProps'

export default ({ activeWorkshop, register, watch, exerciseComponent }: ParticipantProps) => {
  const watchDoesIt = watch('value[does_it]')
  const isYes = watchDoesIt === "Yes, charities in our portfolio should be this size"
  return (
    <>
      <h3>Does a charity’s current revenue matter?</h3>
      <div className="row mt-4">
        <div className="col-12">
          <div className="form-group form-check">
            <input type="radio" className="form-check-input" name="value[does_it]" value="No, revenue doesn’t matter" id="does_it_no" ref={register()} />
            <label className="form-check-label" htmlFor="does_it_no">No, revenue doesn't matter</label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group form-check">
            <input type="radio" className="form-check-input" name="value[does_it]" value="Yes, charities in our portfolio should be this size" id="does_it_yes" ref={register()} />
            <label className="form-check-label" htmlFor="does_it_yes">Yes, charities in our portfolio should be these sizes:</label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-check form-check-inline pl-4 mb-3 align-items-start">
            <input type="checkbox" disabled={!isYes} className="form-check-input mr-2" style={{marginTop: '0.3rem' }} name="value[small]" id="small" ref={register()} />
            <label className="form-check-label" htmlFor="small">Small<br/>(&lt;$1M)</label>
          </div>
          <div className="form-check form-check-inline pl-3 mb-3 align-items-start">
            <input type="checkbox" disabled={!isYes} className="form-check-input mr-2" style={{marginTop: '0.3rem' }} name="value[medium]" id="medium" ref={register()} />
            <label className="form-check-label" htmlFor="medium">Medium<br/>($1-5M)</label>
          </div>
          <div className="form-check form-check-inline pl-3 mb-3 align-items-start">
            <input type="checkbox" disabled={!isYes} className="form-check-input mr-2" style={{marginTop: '0.3rem' }} name="value[large]" id="large" ref={register()} />
            <label className="form-check-label" htmlFor="large">Large<br/>($5M+)</label>
          </div>
        </div>
      </div>
      <div className="text-center mb-5 mt-3">
        <input className="btn btn-primary" type="submit" value="Submit my Answers" />
        <p className="text-muted mt-2">Answers cannot be changed after submitting!</p>
      </div>
    </>
  )
}