import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom"
import firebase from 'components/firebase'
import Workshop from 'components/types/Workshop'
import AppState from 'components/types/AppState'
import Submission from 'components/types/Submission'
import ExerciseComponents from 'components/Exercises'
import ExerciseComponent from 'components/types/ExerciseComponent'

const SUBMISSIONS_COLLECTION: string = process.env.REACT_APP_COLLECTION_SUBMISSIONS as string

export default ({ workshops, activeWorkshop }: AppState) => {
  let { id, sessionId } = useParams<{ id: string, sessionId: string }>()
  const sessionIdNum: number = parseInt(sessionId, 10)
  const workshop = workshops.find((workshop: Workshop) => workshop.id === id)

  const [ submissions, setSubmissions ] = useState<Submission[]>([] as Submission[])

  useEffect(() => {
    if (workshop) {
      firebase
        .firestore()
        .collection(SUBMISSIONS_COLLECTION)
        .where('workshop', '==', workshop.id)
        .get()
        .then((querySnapshot: firebase.firestore.QuerySnapshot) => {
          let nextSubmissions: any[] = []
          querySnapshot.forEach(function(doc: firebase.firestore.DocumentSnapshot) {
            nextSubmissions.push(doc.data())
          })
          setSubmissions(nextSubmissions)
        })
    }
  }, [workshop])

  const valuesByExercise: { [key: string]: any[] } = {}

  submissions.forEach((submission) => {
    submission.exercises.forEach((submissionExercise: { id: string, value: any}) => {
      if (!valuesByExercise[submissionExercise.id]) {
        valuesByExercise[submissionExercise.id] = []
      }
      valuesByExercise[submissionExercise.id].push(submissionExercise)
    })
  })

  if (!workshop || !ExerciseComponents || !sessionIdNum) {
    return <p>Workshop not found.</p>
  }
  else {
    return (
      <>
        <Link to="/admin">&larr; Back</Link>
        <h1 className="xmb-3">{workshop.name} - Session {sessionIdNum + 1} </h1>

        {ExerciseComponents.filter((exercise: ExerciseComponent) => exercise.answerable && exercise.session === sessionIdNum).map((exercise: ExerciseComponent) => {
          let final = <div>No results.</div>
          if (exercise.result && valuesByExercise[exercise.id]) {
            const Result: Function = exercise.result as Function
            final = <Result exerciseSubmissions={valuesByExercise[exercise.id]} activeWorkshop={workshop} />
          }
          return (
            <div className="row">
              <div className="col-lg-8 my-3">
                <div className="card">
                  <div className="card-header">
                    <h5 className="xcard-title">{exercise.name}</h5>
                  </div>
                  <div className="card-body">
                    {final}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </>
    )
  }
}