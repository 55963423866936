import React from 'react'
import ParticipantProps from 'components/Exercises/Participant/ParticipantProps'
import Causes from 'components/Exercises/Causes'
import { machineName } from 'components/Exercises/format'

const TOTAL_CHOICES = 2

export default ({ activeWorkshop, register, watch, exerciseComponent }: ParticipantProps) => {
  const watchAll = watch()
  const numChecked = Object.keys(watchAll).reduce((prev, current) => prev + (watchAll[current] ? 1 : 0), 0)
  return (
    <>
      <p>Choose {TOTAL_CHOICES} sectors you’d like to support:</p>

      {Causes.map(({ name, description }) => <>
        <h3 className="mt-4 mb-1">{name}</h3>
        <p className="mb-2">{description}</p>
        <div className="form-group form-check">
          <input type="checkbox" className="form-check-input" name={`value[${name}]`} id={machineName(name)} ref={register()} />
          <label className="form-check-label" htmlFor={machineName(name)}>Support this sector</label>
        </div>
      </>)}

      <div className="text-center my-5">
        {numChecked !== TOTAL_CHOICES && <div className="text-danger mb-2">Choose {TOTAL_CHOICES} sectors to save your answer.</div>}
        <input disabled={numChecked !== TOTAL_CHOICES} className="btn btn-primary" type="submit" value="Submit my Answers" />
        <p className="text-muted mt-2">Answers cannot be changed after submitting!</p>
      </div>
    </>
  )
}