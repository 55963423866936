import React from 'react'
import AppState from 'components/types/AppState'
import { ExerciseState } from 'components/types/Exercise'

export default ({ activeExercise, activeWorkshop, activeExerciseComponent, Result, exerciseSubmissions }: AppState & { exerciseSubmissions : any[], Result : typeof React.Component }) => {
  const showResults = activeExercise && (activeExercise.state === ExerciseState.Results) && activeExerciseComponent && exerciseSubmissions.length > 0 && activeExerciseComponent.id === exerciseSubmissions[0].id && Result
	return (
		<div className="col-md-10 col-lg-8 col-xl-6 text-md-center">
		  <h1 className="mb-2">Number of Charities</h1>
		  <p>Approximately how many charities should be in our portfolio?</p>
		  {!showResults && <>
        <div className="row mt-4 text-left">
          <div className="col-6">
            <div className="form-group form-check">
              <input type="radio" className="form-check-input" name="value" value="1 &ndash; 3" id="local" />
              <label className="form-check-label" htmlFor="local">1 &ndash; 3</label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group form-check">
              <input type="radio" className="form-check-input" name="value" value="4 &ndash; 7" id="provincial" />
              <label className="form-check-label" htmlFor="provincial">4 &ndash; 7</label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group form-check">
              <input type="radio" className="form-check-input" name="value" value="8 &ndash; 12" id="national" />
              <label className="form-check-label" htmlFor="national">8 &ndash; 12</label>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group form-check">
              <input type="radio" className="form-check-input" name="value" value="12+" id="international" />
              <label className="form-check-label" htmlFor="international">12+</label>
            </div>
          </div>
        </div>
      </>}
      {showResults && <Result exerciseSubmissions={exerciseSubmissions} />}
		</div>
	)
}