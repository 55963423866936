import React from 'react'
import {
  // BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  useRouteMatch,
} from "react-router-dom"

import AppState from 'components/types/AppState'

import Header from 'components/Shared/Header'
import Index from 'components/Admin/List'
import Create from 'components/Admin/Create'
import Result from 'components/Admin/Result'

export default (appState: AppState) => {
  let match = useRouteMatch()

  const { error, loading } = appState

  return (
    <div>
      <Header {...appState} />
      <div className="container py-3">
        {error && <div>An error occurred: {error.message}</div>}
        {loading && <div>Loading...</div>}
        <Switch>
          <Route path={`${match.path}/create`}>
            <Create {...appState} />
          </Route>
          <Route path={`${match.path}/:id/edit`}>
            <Create {...appState} />
          </Route>
          <Route path={`${match.path}/:id/results/:sessionId`}>
            <Result {...appState} />
          </Route>
          <Route path={match.path}>
            <Index {...appState} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

