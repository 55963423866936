import React, { useState } from 'react'
import { Link, useHistory, useParams } from "react-router-dom"

import firebase from 'components/firebase'
import { useForm } from 'react-hook-form'

import Exercises from 'components/Exercises'

import Workshop from 'components/types/Workshop'
import AppState from 'components/types/AppState'
import Charity from 'components/types/Charity'
import { SessionState } from 'components/types/Session'

import { ExerciseState } from 'components/types/Exercise'

interface FormData {
  name: string,
  minCharities?: number;
  maxCharities?: number;
  finalCharities?: number;
  giftAmount?: number;
  charities: Charity[],
}

export default ({ workshops }: AppState) => {
  const history = useHistory();

  let { id } = useParams<{id: string}>()
  const workshop = workshops.find((workshop: Workshop) => workshop.id === id)
  let useFormOptions = {}
  if (workshop) {
    useFormOptions = { defaultValues: workshop as FormData }
  }
  const { register, handleSubmit, errors } = useForm<FormData>(useFormOptions)

  // const create = ({ name }: FormData) => {
  const onSubmit = handleSubmit((formData) => {
    // let record = new Workshop(formData)
    // console.log(record)
    if (workshop) {
      firebase.firestore().collection(process.env.REACT_APP_COLLECTION as string).doc(workshop.id).update({
        name: formData.name,
        updatedAt: new Date(),
        minCharities: typeof formData.minCharities !== 'undefined' ? parseInt(formData.minCharities.toString(), 10) : undefined,
        maxCharities: typeof formData.maxCharities !== 'undefined' ? parseInt(formData.maxCharities.toString(), 10) : undefined,
        finalCharities: typeof formData.finalCharities !== 'undefined' ? parseInt(formData.finalCharities.toString(), 10) : undefined,
        giftAmount: typeof formData.giftAmount !== 'undefined' ? parseInt(formData.giftAmount.toString(), 10) : undefined,
        charities: formData.charities || [],
      }).then(() => history.push("/admin"))
    }
    else {
      const newWorkshopRef = firebase.firestore().collection(process.env.REACT_APP_COLLECTION as string).doc()
      const record: Workshop = {
        id: newWorkshopRef.id,
        name: formData.name,
        createdAt: new Date(),
        updatedAt: new Date(),
        // timer: undefined,
        timerVisible: false,
        minCharities: typeof formData.minCharities !== 'undefined' ? parseInt(formData.minCharities.toString(), 10) : undefined,
        maxCharities: typeof formData.maxCharities !== 'undefined' ? parseInt(formData.maxCharities.toString(), 10) : undefined,
        finalCharities: typeof formData.finalCharities !== 'undefined' ? parseInt(formData.finalCharities.toString(), 10) : undefined,
        giftAmount: typeof formData.giftAmount !== 'undefined' ? parseInt(formData.giftAmount.toString(), 10) : undefined,
        charities: formData.charities || [],
        // currentExercise: undefined,
        sessions: [
          { id: 1, state: SessionState.Ready },
          { id: 2, state: SessionState.Ready },
        ],
        exercises: Exercises.map((exercise) => {
          return {
            id: exercise.id,
            state: ExerciseState.Ready,
          }
        }),
      }
      newWorkshopRef.set(record).then(() => history.push("/admin"))
    }
  })

  const [ charityCount, setCharityCount ] = useState<number>(workshop ? workshop.charities.length : 0)

  const onChangeCharity = (direction: number) => {
    let nextCount = charityCount + direction
    if (nextCount < 0) {
      nextCount = 0
    }
    setCharityCount(nextCount)
  }

  const handleDelete = (id: string | undefined) => {
    if (id && window.confirm('Permanently delete this workshop?')) {
      firebase.firestore().collection(process.env.REACT_APP_COLLECTION as string).doc(id).delete().then(() => history.push("/admin"))
    }
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <h3 className="my-3">General</h3>
        <div className="form-row">
          <div className="form-group col-md-8 col-lg-6">
            <label htmlFor="name">Workshop Name</label>
            <input type="text" className={`form-control ${errors.name && 'is-invalid'}`} id="name" name="name" ref={register({ required: true })} />
            {errors.name && (
              <div className="invalid-feedback">
                Workshop Name is required.
              </div>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-3">
            <label htmlFor="giftAmount">Gift Amount</label>
            <input type="number" className={`form-control`} id="giftAmount" name="giftAmount" ref={register()} />
          </div>
        </div>
        <h3 className="my-3">Number of Charities</h3>
        <div className="form-row">
          <div className="form-group col-3">
            <label htmlFor="minCharities">Minimum</label>
            <input type="number" className={`form-control`} id="minCharities" name="minCharities" ref={register()} />
          </div>
          <div className="form-group col-3">
            <label htmlFor="maxCharities">Maximum</label>
            <input type="number" className={`form-control`} id="maxCharities" name="maxCharities" ref={register()} />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-3">
            <label htmlFor="finalCharities">Final</label>
            <input type="number" className={`form-control`} id="finalCharities" name="finalCharities" ref={register()} />
          </div>
        </div>
        <h3 className="my-3">Charities</h3>
        {[...Array(charityCount)].map((e, i) => (
          <>
          <div className="mb-3">
            <div className="form-row">
              <div className="form-group col-md-3">
                <label htmlFor={`charities-${i}-name`}>Name</label>
                <input type="text" className={`form-control`} id={`charities-${i}-name`} name={`charities[${i}][name]`} ref={register()} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-9 col-lg-7">
                <label htmlFor={`charities-${i}-description`}>Description</label>
                <textarea className={`form-control`} rows={4} id={`charities-${i}-description`} name={`charities[${i}][description]`} ref={register()} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-2">
                <label htmlFor={`charities-${i}-revenue`}>Revenue</label>
                <input type="text" className={`form-control`} id={`charities-${i}-revenue`} name={`charities[${i}][revenue]`} ref={register()} />
              </div>
              <div className="form-group col-md-7 col-lg-5">
                <label htmlFor={`charities-${i}-causes`}>Causes</label>
                <input type="text" className={`form-control`} id={`charities-${i}-causes`} name={`charities[${i}][causes]`} ref={register()} />
              </div>
            </div>
          </div>
          <hr/>
          </>
        ))}
        <div>
          <button type="button" onClick={() => onChangeCharity(1)} className="btn btn-primary">+ Add Charity</button>
          <button type="button" onClick={() => onChangeCharity(-1)} className="btn btn-text ml-2">Remove Charity</button>
        </div>

        <hr/>
        <button type="submit" className="btn btn-primary">Save</button>
        {workshop && <button type="button" className="btn btn-danger ml-3" onClick={() => handleDelete(workshop.id)}>Delete</button>}
        <Link className="btn btn-text ml-1" to="/admin">Cancel</Link>
      </form>
    </>
  )
}