import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useCookies } from 'react-cookie'
import firebase from 'components/firebase'

import AppState from 'components/types/AppState'
import { ExerciseState } from 'components/types/Exercise'
import Submission from 'components/types/Submission'
import Header from 'components/Shared/Header'

const SUBMISSIONS_COLLECTION: string = process.env.REACT_APP_COLLECTION_SUBMISSIONS as string

export default (appState: AppState) => {
  const { error, loading, activeWorkshop, activeExerciseComponent, activeExercise } = appState

  const [ cookies, setCookie ] = useCookies(['submission'])
  const [ submission, setSubmission ] = useState<Submission>()

  const db = firebase.firestore()

  if (!submission && activeWorkshop) {
    if (cookies.submission && cookies.submission.workshop === activeWorkshop.id) {
      db.collection(SUBMISSIONS_COLLECTION).doc(cookies.submission.id).get().then((doc) => {
        if (doc.exists) {
          setSubmission(doc.data() as Submission)
        }
      })
    }
    else {
      const newSubmissionRef = db.collection(SUBMISSIONS_COLLECTION).doc()
      const data: Submission = {
        id: newSubmissionRef.id,
        workshop: activeWorkshop.id,
        createdAt: new Date(),
        updatedAt: new Date(),
        exercises: []
      }
      setCookie('submission', data, { maxAge: 60*60*24*365 })
      newSubmissionRef.set(data)
      setSubmission(data)
    }
  }

  const handleSubmissionChange = (doc: firebase.firestore.DocumentSnapshot) => {
    const nextSubmission: Submission = { id: doc.id, ...doc.data() } as Submission
    setSubmission(nextSubmission)
  }

  useEffect(() => {
    if (submission) {
      const unsubscribe = db.collection(SUBMISSIONS_COLLECTION).doc(submission.id).onSnapshot(handleSubmissionChange)
      return unsubscribe
    }
  }, [db, JSON.stringify(submission)])

  // Set up form.
  const { register, watch, handleSubmit } = useForm<{ value: any }>()
  const onSubmit = handleSubmit((newData: { value: any }) => {
    if (submission && activeExerciseComponent) {
      submission.exercises.push({
        id: activeExerciseComponent.id,
        value: newData.value
      })
      submission.updatedAt = new Date()
      window.scrollTo(0,0)
      db.collection(SUBMISSIONS_COLLECTION).doc(submission.id).update(submission)
    }
  })

  // Get final component ready.
  let final = null
  if (activeExerciseComponent && submission && activeExerciseComponent && !!submission.exercises.find((ex) => ex.id === activeExerciseComponent.id)) {
    // const hi = submission.exercises.find((ex) => ex.id === activeExerciseComponent.id)
    final = <>
      <div>Answer has been saved.</div>
      {/*<div>{hi && JSON.stringify(hi)}</div>*/}
    </>
  }
  else if (activeWorkshop && activeExerciseComponent && activeExercise && ((ExerciseState.Running && !activeExerciseComponent.answerable) || (ExerciseState.Answerable === activeExercise.state)) ) {
    const Participant = activeExerciseComponent.participant as Function
    final = <Participant {...appState} register={register} watch={watch} submission={submission} exerciseComponent={activeExerciseComponent}  />
  }
  else {
    final = <div>No active exercises.</div>
  }

  return (
    <div>
      <Header {...appState} />
      {activeExerciseComponent && <div className="p-2 xbg-light xtext-white" style={{backgroundColor: 'rgb(240,240,240)'}}>
        <div className="container">{activeExerciseComponent.name}</div>
      </div>}
      {error && <div>An error occurred: {error.message}</div>}
      {loading && <div>Loading...</div>}
      <div className="container py-3" style={{ minHeight: 'calc(100vh - 250px)' }}>
        <form onSubmit={onSubmit} className="col-sm-12 col-md-10 col-lg-8 col-xl-6 px-0">
          {final}
        </form>
      </div>
    </div>
  )
}
