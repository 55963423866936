import React, { FunctionComponent, useState } from 'react'

import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

const Expando: FunctionComponent = ({ children }) => {
  const [ open, setOpen ] = useState(false)
  const toggleOpen = () => setOpen(!open)
  return (
    <div>
      <button type="button" onClick={toggleOpen} className="btn btn-primary my-3">{open ? 'Less' : 'More'}</button>
      <SlideDown className={'blueocean-dropdown-slidedown'}>
        { open && children }
      </SlideDown>
    </div>
  )
}

export default Expando