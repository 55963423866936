import React from 'react'
import ParticipantProps from 'components/Exercises/Participant/ParticipantProps'

export default ({ activeWorkshop, register }: ParticipantProps) => {
  return (
    <>
      <h3>If we give money more than once, we should commit to the same charities for multiple years.</h3>
      <div className="row mt-4">
        <div className="col-12">
          <input name="value" type="range" className="form-control-range" ref={register()} />
        </div>
      </div>
      <div className="row mt-2 xtext-muted">
        <div className="col-6">Disagree</div>
        <div className="col-6 text-right">Agree</div>
      </div>
      <div className="text-center my-5">
        <input className="btn btn-primary" type="submit" value="Submit my Answers" />
        <p className="text-muted mt-2">Answers cannot be changed after submitting!</p>
      </div>
    </>
  )
}