import React from 'react'
import { Link } from "react-router-dom"
import CopyToClipboard from 'react-copy-to-clipboard'

import firebase from 'components/firebase'

import Workshop from 'components/types/Workshop'
import AppState from 'components/types/AppState'
import { SessionState } from 'components/types/Session'

export default ({ workshops }: AppState) => {

  const advanceSessionState = (workshop: Workshop, sessionId: number, state: SessionState) => {
    let nextSessions = workshop.sessions
    const index = nextSessions.findIndex((session) => session.id === sessionId)
    nextSessions[index].state = state
    firebase.firestore().collection(process.env.REACT_APP_COLLECTION as string).doc(workshop.id).update({
      updatedAt: new Date(),
      sessions: nextSessions,
    })
  }

  const displaySession = (id: number): string => {
    return `Session ${id + 1}`
  }

  return (
    <>
      <Link className="btn btn-primary" to="/admin/create">New Workshop</Link>
      {workshops.map((workshop) => (
        <div className="card my-3" key={workshop.id}>
          <div className="card-body">
            <h2 className="card-title">{workshop.name} <Link to={`/admin/${workshop.id}/edit`} className="btn btn-primary btn-sm">Edit</Link></h2>
            {workshop.sessions.map((session) => (
              <div key={session.id} className="card-text">
                {session.state === SessionState.Ready && workshop.sessions && (
                  <>{(session.id === 1 || workshop.sessions.find(session => session.id === 1 && session.state === SessionState.Done )) ?
                    <div className="my-2 btn btn-link p-0" onClick={() => advanceSessionState(workshop, session.id, SessionState.Running)}>{displaySession(session.id)} - Start</div>
                    :
                    <div className="my-2 p-0">{displaySession(session.id)} - Start</div>
                  }</>
                )}
                {session.state === SessionState.Running && (
                  <div className="my-3">
                    <div><strong>{displaySession(session.id)} - In Progress</strong></div>
                    <div className="border-left pl-3 mt-3 border-primary">
                      <div className="table-responsive">
                        <table className="my-2 table">
                          <tbody>
                            <tr>
                              <td>Participant View</td>
                              <td><Link to="/">go.blueoceangiving.com</Link></td>
                              <td><CopyToClipboard text="https://go.blueoceangiving.com"><span className="btn btn-link p-0">Copy Link</span></CopyToClipboard></td>
                            </tr>
                            <tr>
                              <td>Facilitator View</td>
                              <td><Link to="/facilitator">go.blueoceangiving.com/facilitator</Link></td>
                              <td><CopyToClipboard text="https://go.blueoceangiving.com/facilitator"><span className="btn btn-link p-0">Copy Link</span></CopyToClipboard></td>
                            </tr>
                            <tr>
                              <td>Presenter View</td>
                              <td><Link to="/presenter">go.blueoceangiving.com/presenter</Link></td>
                              <td><CopyToClipboard text="https://go.blueoceangiving.com/presenter"><span className="btn btn-link p-0">Copy Link</span></CopyToClipboard></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="btn btn-link p-0" onClick={() => advanceSessionState(workshop, session.id, SessionState.Done)}>{displaySession(session.id)} - Close</div>
                    </div>
                  </div>
                )}
                {session.state === SessionState.Done && <Link className="my-2 btn btn-link p-0" to={`/admin/${workshop.id}/results/${session.id}`}>{displaySession(session.id)} - Results</Link>}
              </div>
            ))}
          </div>
        </div>
        ))}
    </>
  )
}