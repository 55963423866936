import React from 'react'

const SORTED_SCORES = [ 5, 4, 3, 2, 1 ]

export default ({ exerciseSubmissions }: { exerciseSubmissions : { id: string, value: string[] }[] }) => {
  let counters : { id: string, value: number }[] = []
  exerciseSubmissions.forEach((submission, index) => {
    submission.value.forEach((value, valueIndex) => {
      let index = counters.findIndex(count => count.id === value)
      if (index === -1) {
        index = counters.length
        counters.push({ id: value, value: 0 })
      }
      if (submission.value[valueIndex]) {
        counters[index].value += SORTED_SCORES[valueIndex]
      }
    })
  })
  return (
    <div className="xrow mt-4 text-left">
      {counters.sort((a, b) => b.value - a.value).map(({ id, value }) => <>
        <div className="row mb-3">
          <h4 className="col-4 text-right">{value}pts&nbsp;</h4>
          <h4 className="col-8"><strong>{id}</strong></h4>
        </div>
      </>)}
    </div>
  )
}
