import React, { useState, useEffect } from 'react'
// import useDeepCompareEffect from 'use-deep-compare-effect'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom"

import firebase from 'components/firebase'

import Workshop from 'components/types/Workshop'
import AppState from 'components/types/AppState'
import Error from 'components/types/Error'
import { SessionState } from 'components/types/Session'
import { RunnableStates } from 'components/types/Exercise'
import ExerciseComponents from 'components/Exercises'

import Admin from 'components/Admin'
import Presenter from 'components/Presenter'
import Facilitator from 'components/Facilitator'
import Participant from 'components/Participant'

// let first = 0

export default () => {
  const [ appState, setAppState ] = useState<AppState>({ loading: true, workshops: [] })

  function handleWorkshopChange(querySnapshot: firebase.firestore.QuerySnapshot) {
    let nextAppState: AppState = {
      loading: false,
      workshops: [],
      // workshops: new Array<Workshop>(),
      // workshops: Workshop[],
      // workshops: [] as Workshop,
      // error: undefined,
    }
    querySnapshot.forEach((doc: firebase.firestore.DocumentSnapshot) => {
      const workshop: Workshop = { id: doc.id, ...doc.data() } as Workshop
      nextAppState.workshops.push(workshop)
    })
    nextAppState.activeWorkshop = nextAppState.workshops.find(workshop => workshop.sessions.find(session => session.state === SessionState.Running))
    if (nextAppState.activeWorkshop) {
      nextAppState.activeExercise = nextAppState.activeWorkshop.exercises.find(exercise => RunnableStates.includes(exercise.state))
      nextAppState.activeExerciseComponent = ExerciseComponents.find(ec => nextAppState.activeExercise && ec.id === nextAppState.activeExercise.id)
    }
    // if (first < 10) {
      // console.log(JSON.stringify(workshops))
      // console.log('nextAppState', JSON.stringify(nextAppState.activeExercise ? nextAppState.activeExercise.id : 'no activeExercise'))
      // console.log('nextAppState', JSON.stringify(nextAppState.activeExerciseComponent ? nextAppState.activeExerciseComponent.id : 'no activeExerciseComponent'))
      // console.log('nextAppState', JSON.stringify(nextAppState.activeExerciseComponent ? nextAppState.activeExerciseComponent.result : 'no activeExerciseComponent'))
      // console.log(workshops == nextAppState)
      setAppState({ ...appState, ...nextAppState })
      // first += 1
    // }
  }

  function handleError(error: Error) {
    setAppState({
      ...appState,
      loading: false,
      workshops: [],
      error: error as Error,
    })
  }

  useEffect(() => {
    const auth = firebase.auth()
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        // var isAnonymous = user.isAnonymous;
        // var uid = user.uid;
        // console.log(JSON.stringify(appState))
        setAppState({ ...appState, signedIn: true })
      }
      else {
        // User is signed out.
        setAppState({ ...appState, signedIn: false })
        auth.signInAnonymously()
      }
    });
    return unsubscribe;
  }, [appState.signedIn])

  // If we just compare [workshops] with useEffect, we're just comparing by reference.
  useEffect(() => {
    if (appState.signedIn) {
      const db = firebase.firestore()
      const unsubscribe = db.collection(process.env.REACT_APP_COLLECTION as string).orderBy('createdAt', 'desc').onSnapshot(handleWorkshopChange, handleError)
       // For dev, don't let this connection linger forever.
      // setTimeout(() => {
      //   console.log('Dev Timeout')
      //   unsubscribe()
      // }, 1000*30)
      return unsubscribe
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(appState)]) // JSON.stringify is a hack, useEffect / and useDeepCompareEffect can't figure out what's changing

  if (!appState.signedIn) {
    return <div>Signing in</div>
  }
  return (
    <Router>
      <Switch>
        <Route path="/admin">
          <Admin {...appState} />
        </Route>
        <Route path="/presenter">
          <Presenter {...appState} />
        </Route>
        <Route path="/facilitator">
          <Facilitator {...appState} />
        </Route>
        <Route path="/">
          <Participant {...appState} />
        </Route>
      </Switch>
    </Router>
  )
}
