import React from 'react'
import Range from 'components/Exercises/Result/Range'
import Workshop from 'components/types/Workshop'
import { currency, percent } from 'components/Exercises/format'

const MIN = 5
const MAX = 100

export default ({ exerciseSubmissions, activeWorkshop }: { exerciseSubmissions : any[], activeWorkshop: Workshop }) => {
  if (!activeWorkshop || !activeWorkshop.giftAmount) {
    return <div>Number of charities or final gift amount need to be configured.</div>
  }

  const giftAmount: number = activeWorkshop.giftAmount

  const length = exerciseSubmissions && exerciseSubmissions.length > 0 ? exerciseSubmissions[0].length : 0
  const graphValues : any[] = exerciseSubmissions.map((submission: { id: string, value: string[] }) => {
    return submission.value.map(val => parseInt(val, 10)).sort((a, b) => b - a)
  }).reduce((prev, next: number[]) => {
    next.forEach((value, index) => {
      if (!prev[index]) {
        prev[index] = []
      }
      prev[index].push(value)
    })
    return prev
  }, Array(length) as number[][])

  return (
    <div className="pt-2">
      {graphValues.map((values, index) => {
        const average = values.reduce((prev: number, next: number) => prev + next, 0) / values.length
        return <>
          <div className="mt-4 text-left">
            <h4><strong>Charity {index+1}:</strong> {currency((average / 100) * giftAmount)}</h4>
            <div className="d-flex">
              <div className="flex-grow-1">
                <div className="row mt-2">
                  <div className="col-12">
                    <Range values={values} min={5} max={100} />
                  </div>
                </div>
                <div className="row mt-0">
                  <div className="col-6">{MIN}%</div>
                  <div className="col-6 text-right">{MAX}%</div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end pl-3 pr-3 mb-3" style={{width: '100%', maxWidth: '90px'}}>
                <h4><strong>{percent(average, 100)}</strong></h4>
              </div>
            </div>
          </div>
        </>
      })}
    </div>
  )
}