import React from 'react'

export default () => {
  return (
    <div className="col-md-10 col-lg-8 col-xl-6 text-md-center">
      <h1 className="mb-2">Welcome!</h1>
      <p>Join the session by visiting this website on your phone, tablet, or computer:</p>
      <a href="https://go.blueoceangiving.com">go.blueoceangiving.com</a>
      <p className="mt-3">We will use the webpage during the session, keep your device close at hand and turned on.</p>
    </div>
  )
}