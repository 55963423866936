import React, { useEffect, useState } from 'react'
import firebase from 'components/firebase'
import { percent, currency } from 'components/Exercises/format'
import Workshop from 'components/types/Workshop'

const SUBMISSIONS_COLLECTION: string = process.env.REACT_APP_COLLECTION_SUBMISSIONS as string

interface CounterState {
   id: string
   value: number
   reveal: boolean
}

export default ({
  exerciseSubmissions,
  // distributionSubmissions,
  activeWorkshop,
  revealed = true,
  fireworks = false
}: {
  exerciseSubmissions : { id: string, value: string[] }[],
  // distributionSubmissions: any[],
  activeWorkshop: Workshop,
  revealed?: boolean,
  fireworks?: boolean,
}) => {
  const [ counters, setCounters ] = useState<CounterState[]>([])
  const [ distributionSubmissions, setDistributionSubmissions ] = useState<any[]>([] as any[])

  useEffect(() => {
    if (activeWorkshop) {
      firebase
        .firestore()
        .collection(SUBMISSIONS_COLLECTION)
        .where('workshop', '==', activeWorkshop.id)
        .get()
        .then((querySnapshot: firebase.firestore.QuerySnapshot) => {
          let nextDistributionSubmissions: any[] = []
          querySnapshot.forEach(function(doc: firebase.firestore.DocumentSnapshot) {
            let submission = doc.data()
            if (submission) {
              let exerciseSubmission = submission.exercises.find((ex: { id: string, value: any }) => ex.id === '_2_03_Distributing_Our_Gift')
              if (exerciseSubmission) {
                nextDistributionSubmissions.push(exerciseSubmission)
              }
            }
          })
          setDistributionSubmissions([...nextDistributionSubmissions])
        })
    }
  }, [activeWorkshop, exerciseSubmissions.length])

  if (!exerciseSubmissions[0]) {
    return null
  }
  if (!activeWorkshop || !activeWorkshop.giftAmount) {
    return <div>Number of charities or final gift amount need to be configured.</div>
  }
  const length = exerciseSubmissions[0].value.length
  let SORTED_SCORES: number[] =  []
  for (var i = 0; i < length; i++) {
    SORTED_SCORES.push(length - i)
  }

  const charities = activeWorkshop.charities.map((charity, index) => { return {
    ...charity,
    fakeName: `Charity ${index+1}`,
  }})

  const toggleReveal = (index: number): void => {
    counters[index].reveal = !counters[index].reveal
    setCounters([...counters])
  }

  if (counters.length !== exerciseSubmissions[0].value.length) {
    let nextCounters: CounterState[] = []
    exerciseSubmissions.forEach((submission, index) => {
      submission.value.forEach((value, valueIndex) => {
        let index = nextCounters.findIndex(count => count.id === value)
        if (index === -1) {
          index = nextCounters.length
          nextCounters.push({ id: value, value: 0, reveal: revealed })
        }
        if (submission.value[valueIndex]) {
          nextCounters[index].value += SORTED_SCORES[valueIndex]
        }
      })
    })
    setCounters(nextCounters)
  }

  const giftAmount: number = activeWorkshop.giftAmount

  const distLength = distributionSubmissions && distributionSubmissions.length > 0 ? distributionSubmissions[0].length : 0
  const graphValues : any[] = distributionSubmissions === undefined ? [] : distributionSubmissions.map((submission: { id: string, value: string[] }) => {
    return submission.value.map(val => parseInt(val, 10)).sort((a, b) => b - a)
  }).reduce((prev, next: number[]) => {
    next.forEach((value, index) => {
      if (!prev[index]) {
        prev[index] = []
      }
      prev[index].push(value)
    })
    return prev
  }, Array(distLength) as number[][]).reduce((prev: number[], next: number[], index: number) => {
    const sum = next.reduce((prev, next) => prev + next, 0)
    prev[index] = sum / next.length
    return prev
  }, Array(distLength) as number[])

  return (
    <div className="text-left">
      {counters.sort((a, b) => b.value - a.value).slice(0, graphValues.length).map(({ id, value, reveal }, index) => {
        const charity = charities.find(charity => charity.name === id)
        if (!charity) {
          return <div>Unable to match data.</div>
        }
        return <>
          <div className="row my-4" onClick={() => toggleReveal(index)}>
            <div className="col-8">
              <h4>
                <strong>{index+1}. {reveal ? `${charity.name}:` : '__________?'}</strong> {reveal && `${value}pts`}
                {reveal && fireworks && <div className="pyro"><div className="before"></div></div>}
              </h4>
            </div>
            <div className="col-4">
              <h4>{currency((graphValues[index] / 100) * giftAmount)} ({percent(graphValues[index], 100)})</h4>
            </div>
          </div>
        </>
      })}
      <hr/>
      <div className="row mt-3">
        <div className="col-8">
          <h4><strong>Total Gift</strong></h4>
        </div>
        <div className="col-4">
          <h4>{currency(giftAmount)}</h4>
        </div>
      </div>
    </div>
  )
}