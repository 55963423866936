import React from 'react'

const Point = ({ type, value, min, max } : { type: string, value: number, min: number, max: number }) => {
  return <div style={{ left: ((value - min) / (max - min))* 100 + '%' }} className={'point-' + type}><span>{Math.floor(value * 10)/10}</span></div>
}

export default ({ values, min = 0, max = 100 } : { values: number[], min?: number, max?: number }) => {
  const average = values.length > 0 ? values.reduce((prev, current) => prev + current, 0) / values.length : 50

  function median(array: number[]) {
    array.sort(function(a, b) {
      return a - b;
    });
    var mid = array.length / 2;
    return mid % 1 ? array[mid - 0.5] : (array[mid - 1] + array[mid]) / 2;
  }
  const med = median([...values])

  return <div className="graph">
    {values.map((value: number, index: number) => <Point key={index} type="regular" value={value} min={min} max={max} />)}
    <Point type="average" value={average} min={min} max={max} />
    <Point type="median" value={med} min={min} max={max} />
  </div>
}