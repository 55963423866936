import React from 'react'
import AppState from 'components/types/AppState'
import { ExerciseState } from 'components/types/Exercise'

export default ({ activeExercise, activeWorkshop, activeExerciseComponent, Result, exerciseSubmissions }: AppState & { exerciseSubmissions : any[], Result : typeof React.Component }) => {
	const showResults = activeExercise && (activeExercise.state === ExerciseState.Results) && activeExerciseComponent && exerciseSubmissions.length > 0 && activeExerciseComponent.id === exerciseSubmissions[0].id && Result
	return (
		<div className="col-md-10 col-lg-8 col-xl-6 text-md-center">
		  <h1 className="mb-2">Distributing Our Gift</h1>
		  {!showResults && <>
		  	<p>How should we portion out our gift to receiving charities?</p>
		  </>}
      {showResults && <Result exerciseSubmissions={exerciseSubmissions} activeExercise={activeExercise} activeWorkshop={activeWorkshop} />}
		</div>
	)
}