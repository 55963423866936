import React from 'react'
import { percent } from 'components/Exercises/format'

export default ({ exerciseSubmissions }: { exerciseSubmissions : { id: string, value: string }[] }) => {
  let counters : { id: string, value: number }[] = []
  exerciseSubmissions.forEach((submission) => {
    let index = counters.findIndex((count) => count.id === submission.value)
    if (index === -1) {
      index = counters.length
      counters.push({ id: submission.value, value: 0 })
    }
    counters[index].value += 1
  })
  return (
    <div className="row mt-4 text-left">
      {counters.sort((a, b) => b.value - a.value).map(({ id, value }) => <>
        <div className="col-6 mb-3">
          <h4><strong>{id}:</strong> {percent(value, exerciseSubmissions.length)} ({value})</h4>
        </div>
      </>)}
    </div>
  )
}