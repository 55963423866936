import React from 'react'
import ParticipantProps from 'components/Exercises/Participant/ParticipantProps'
import Causes from 'components/Exercises/Causes'
import { machineName } from 'components/Exercises/format'

export default ({ submission }: ParticipantProps) => {
  let prevExercise: { id: string, value: any } | undefined
  if (submission) {
    prevExercise = submission.exercises.find((exercise) => exercise.id === '_1_09_First_Impressions_Choose_Sectors')
  }
  if (!prevExercise) {
    return <div>No previous exercise found.</div>
  }
  return (
    <>
      <p>Discuss the choices of sectors. Your choices were:</p>
      {Causes.map(({ name, description }) => <>
        <h3 className="mt-4 mb-1">{name}</h3>
        <p className="mb-2">{description}</p>
        <div className="form-group form-check">
          <input checked={prevExercise && prevExercise.value[name]} disabled={true} type="checkbox" className="form-check-input" name={`value[${name}]`} id={machineName(name)} />
          <label className={"form-check-label" + (prevExercise && prevExercise.value[name] ? ' text-success' : '')}>Support this sector</label>
        </div>
      </>)}
    </>
  )
}