import React from 'react'
import Range from 'components/Exercises/Result/Range'
import Workshop from 'components/types/Workshop'

export default ({ exerciseSubmissions, activeWorkshop }: { exerciseSubmissions : any[], activeWorkshop: Workshop }) => {
  const graphValues : number[] = exerciseSubmissions.map((submission) => { return parseInt(submission.value, 10) })
  const min: number = activeWorkshop && activeWorkshop.minCharities ? activeWorkshop.minCharities : 1
  const max: number = activeWorkshop && activeWorkshop.maxCharities ? activeWorkshop.maxCharities : 6
  let steps = []
  for (let i = min; i <= max; i++) {
    steps.push(i)
  }
  return (
    <div className="pt-2">
      <Range values={graphValues} min={activeWorkshop.minCharities} max={activeWorkshop.maxCharities} />
      <div className="row mt-2 xtext-muted">
        <div className="col-12 d-flex justify-content-between">
          {steps.map((step) => {
            return <div className={`text-center`}>{step}</div>
          })}
        </div>
      </div>
    </div>
  )
}