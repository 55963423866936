import React from 'react'
import AppState from 'components/types/AppState'
import { ExerciseState } from 'components/types/Exercise'

export default ({ activeExercise, activeWorkshop, activeExerciseComponent, Result, exerciseSubmissions }: AppState & { exerciseSubmissions : any[], Result : typeof React.Component }) => {
  const showResults = activeExercise && (activeExercise.state === ExerciseState.Results) && activeExerciseComponent && exerciseSubmissions.length > 0 && activeExerciseComponent.id === exerciseSubmissions[0].id && Result
	return (
		<div className="col-md-10 col-lg-8 col-xl-6 text-md-center">
		  <h1 className="mb-2">Does Revenue Matter?</h1>
		  <p>Does a charity's current revenue matter? If yes, what size of charities should be in our portfolio?</p>
		  {!showResults && <>
        <div className="col-12 text-left">
          <div className="form-group form-check">
            <input type="radio" className="form-check-input" name="value[does_it]" value="No, revenue doesn’t matter" id="does_it_no" />
            <label className="form-check-label" htmlFor="does_it_no">No, revenue doesn't matter</label>
          </div>
        </div>
        <div className="col-12 text-left">
          <div className="form-group form-check">
            <input type="radio" className="form-check-input" name="value[does_it]" value="Yes, charities in our portfolio should be this size" id="does_it_yes" />
            <label className="form-check-label" htmlFor="does_it_yes">Yes, charities in our portfolio should be these sizes:</label>
          </div>
        </div>
        <div className="col-12 text-left">
          <div className="form-check form-check-inline pl-4 mb-3 align-items-start">
            <input type="checkbox" className="form-check-input mr-2" style={{marginTop: '0.3rem' }} name="value[small]" id="small" />
            <label className="form-check-label" htmlFor="small">Small<br/>(&lt;$1M)</label>
          </div>
          <div className="form-check form-check-inline pl-3 mb-3 align-items-start">
            <input type="checkbox" className="form-check-input mr-2" style={{marginTop: '0.3rem' }} name="value[medium]" id="medium" />
            <label className="form-check-label" htmlFor="medium">Medium<br/>($1-5M)</label>
          </div>
          <div className="form-check form-check-inline pl-3 mb-3 align-items-start">
            <input type="checkbox" className="form-check-input mr-2" style={{marginTop: '0.3rem' }} name="value[large]" id="large" />
            <label className="form-check-label" htmlFor="large">Large<br/>($5M+)</label>
          </div>
        </div>
      </>}
      {showResults && <Result exerciseSubmissions={exerciseSubmissions} />}
		</div>
	)
}