import React from 'react'
import AppState from 'components/types/AppState'
import { ExerciseState } from 'components/types/Exercise'
import map from 'images/Canada_blank_map.svg'

export default ({ activeExercise, activeWorkshop, activeExerciseComponent, Result, exerciseSubmissions }: AppState & { exerciseSubmissions : any[], Result : typeof React.Component }) => {
  const showResults = activeExercise && (activeExercise.state === ExerciseState.Results) && activeExerciseComponent && exerciseSubmissions.length > 0 && activeExerciseComponent.id === exerciseSubmissions[0].id && Result
  return (
    <div className="xrow col-12 d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${map})`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', minHeight: '75vh' }}>
      <div className="col-md-10 col-lg-8 col-xl-6 text-md-center">
        <h1 className="mb-2">Scale of Impact</h1>
        <p>What scale of impact should charities in our portfolio try to make?</p>
        {!showResults && <>
          <div className="row mt-4 text-left">
            <div className="col-6">
              <div className="form-group form-check">
                <input type="checkbox" className="form-check-input" name="value[Local]" id="local" />
                <label className="form-check-label" htmlFor="local">Local</label>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group form-check">
                <input type="checkbox" className="form-check-input" name="value[Provincial]" id="provincial" />
                <label className="form-check-label" htmlFor="provincial">Provincial</label>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group form-check">
                <input type="checkbox" className="form-check-input" name="value[National]" id="national" />
                <label className="form-check-label" htmlFor="national">National</label>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group form-check">
                <input type="checkbox" className="form-check-input" name="value[International]" id="international" />
                <label className="form-check-label" htmlFor="international">International</label>
              </div>
            </div>
          </div>
        </>}
        {showResults && <Result exerciseSubmissions={exerciseSubmissions} />}
      </div>
    </div>
  )
}