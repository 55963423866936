import React, { useEffect, useState } from 'react'
import firebase from 'components/firebase'
import AppState from 'components/types/AppState'

const SUBMISSIONS_COLLECTION: string = process.env.REACT_APP_COLLECTION_SUBMISSIONS as string

export default ({ activeExercise, activeWorkshop, activeExerciseComponent, Result, exerciseSubmissions }: AppState & { exerciseSubmissions : any[], Result : typeof React.Component }) => {
  const [ prevExerciseSubmissions, setPrevExerciseSubmissions ] = useState<any[]>([] as any[])

  useEffect(() => {
    if (activeWorkshop) {
      firebase
        .firestore()
        .collection(SUBMISSIONS_COLLECTION)
        .where('workshop', '==', activeWorkshop.id)
        .get()
        .then((querySnapshot: firebase.firestore.QuerySnapshot) => {
          let nextPrevExerciseSubmissions: any[] = []
          querySnapshot.forEach(function(doc: firebase.firestore.DocumentSnapshot) {
            let submission = doc.data()
            if (submission) {
              let exerciseSubmission = submission.exercises.find((ex: { id: string, value: any }) => ex.id === '_1_11_Confirm_Sector_Choices')
              if (exerciseSubmission) {
                nextPrevExerciseSubmissions.push(exerciseSubmission)
              }
            }
          })
          setPrevExerciseSubmissions([...nextPrevExerciseSubmissions])
        })
    }
  }, [activeWorkshop, activeExercise, exerciseSubmissions.length])
	return (
		<div className="col-md-12 col-lg-12 col-xl-12 text-md-center">
		  <h1 className="mb-2">Discuss Causes</h1>
	  	<Result exerciseSubmissions={prevExerciseSubmissions} />
		</div>
	)
}