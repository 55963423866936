import React, { useEffect, useState } from 'react'

import firebase from 'components/firebase'
import Header from 'components/Shared/Header'
import AppState from 'components/types/AppState'
import Exercise, { ExerciseState, RunnableStates } from 'components/types/Exercise'
import ExerciseComponent from 'components/types/ExerciseComponent'
import { SessionState } from 'components/types/Session'
import Submission from 'components/types/Submission'
import ExerciseComponents from 'components/Exercises'

const SUBMISSIONS_COLLECTION: string = process.env.REACT_APP_COLLECTION_SUBMISSIONS as string
const WORKSHOPS_COLLECTION: string = process.env.REACT_APP_COLLECTION as string

export default (appState: AppState) => {
  const { activeWorkshop, activeExercise } = appState

  const [ answeredCount, setAnsweredCount ] = useState<number>(0)

  const handleSubmissionChange = (querySnapshot: firebase.firestore.QuerySnapshot) => {
    let count: number = 0
    querySnapshot.forEach(function(doc: firebase.firestore.DocumentSnapshot) {
      const submission: Submission = doc.data() as Submission
      if (activeExercise && !!submission.exercises.find((exercise) => exercise.id === activeExercise.id)) {
        count += 1
      }
    })
    setAnsweredCount(count)
  }

  useEffect(() => {
    if (activeWorkshop) {
      return firebase.firestore().collection(SUBMISSIONS_COLLECTION).where('workshop', '==', activeWorkshop.id).onSnapshot(handleSubmissionChange)
    }
  }, [activeWorkshop, activeExercise])

  if (!activeWorkshop) { return <div><Header {...appState}/><div className="container py-3"><p>No active workshop.</p></div></div> }
  const session = activeWorkshop.sessions.find((session) => session.state === SessionState.Running)
  if (!session) { return <div><Header {...appState}/><div className="container py-3"><p>No active workshop.</p></div></div> }

  interface ExerciseInfo {
    exercise: Exercise,
    exerciseComponent: ExerciseComponent
  }

  let availableExercises: ExerciseInfo[] = []
  let currentExercise : ExerciseInfo | undefined

  activeWorkshop.exercises.forEach((exercise) => {
    const exerciseComponent = ExerciseComponents.find((a) => a.id === exercise.id)
    if (exerciseComponent && exerciseComponent.session === session.id) {
      const available = {
        exercise: exercise,
        exerciseComponent: exerciseComponent,
      }
      availableExercises.push(available)
      if (RunnableStates.includes(exercise.state)) {
        currentExercise = available
      }
    }
  })

  const done = !availableExercises.find(({ exercise }) => exercise.state !== ExerciseState.Done)

  const advanceExerciseState = (state: ExerciseState, exercise?: ExerciseInfo) => {
    if (exercise) {
      const potentialNextIndex = 1 + availableExercises.findIndex((e) => e.exercise.id === exercise.exercise.id)
      let nextExercise : Exercise | null = null
      if (potentialNextIndex < availableExercises.length) {
        nextExercise = availableExercises[potentialNextIndex].exercise
      }

      let nextExercises = activeWorkshop.exercises.map((exerciseIterate) => {
        if (exerciseIterate.id === exercise.exercise.id) {
          exerciseIterate.state = state
        }
        // Auto-advance next exercise to Running.
        if (nextExercise && state === ExerciseState.Done && nextExercise.id === exerciseIterate.id) {
          exerciseIterate.state = ExerciseState.Running
        }
        return exerciseIterate
      })

      firebase.firestore().collection(WORKSHOPS_COLLECTION).doc(activeWorkshop.id).update({
        updatedAt: new Date(),
        exercises: nextExercises,
      })
    }
  }

  const setExerciseRunning = (exerciseInfo: ExerciseInfo) => {
    let nextExercises = activeWorkshop.exercises.map((exerciseIterate) => {
      if (exerciseIterate.id === exerciseInfo.exercise.id) {
        exerciseIterate.state = ExerciseState.Running
      }
      else if (RunnableStates.indexOf(exerciseIterate.state) > -1) {
        exerciseIterate.state = ExerciseState.Done
      }
      return exerciseIterate
    })

    firebase.firestore().collection(WORKSHOPS_COLLECTION).doc(activeWorkshop.id).update({
      updatedAt: new Date(),
      exercises: nextExercises,
    })
  }

  return (
    <div>
      <Header {...appState} />
      <div className="container pt-3 pb-5">
        <div className="card bg-light">
          <div className="card-body">
            {!done && !currentExercise && <h5 className="card-title">Welcome</h5>}
            {!done && currentExercise && <h5 className="card-title">{currentExercise.exerciseComponent.name}</h5>}
            {!done && currentExercise && currentExercise.exerciseComponent.answerable && <p>{answeredCount} people have answered</p>}
            {done && <h5 className="card-title mb-0">Session Complete</h5>}
            {!currentExercise && !done && <button className="btn btn-primary" onClick={() => { advanceExerciseState(ExerciseState.Running, availableExercises[0]) }}>Start Session</button>}
            {currentExercise && currentExercise.exercise.state === ExerciseState.Running && currentExercise.exerciseComponent.answerable && <button className="btn btn-primary" onClick={() => { advanceExerciseState(ExerciseState.Answerable, currentExercise) }}>Open Answers</button>}
            {currentExercise && currentExercise.exercise.state === ExerciseState.Answerable && currentExercise.exerciseComponent.answerable && <button className="btn btn-primary" onClick={() => { advanceExerciseState(ExerciseState.Locked, currentExercise) }}>Close Answers</button>}
            {currentExercise && currentExercise.exercise.state === ExerciseState.Running && !currentExercise.exerciseComponent.answerable && <button className="btn btn-primary" onClick={() => { advanceExerciseState(ExerciseState.Done, currentExercise) }}>Next Exercise</button>}
            {currentExercise && currentExercise.exercise.state === ExerciseState.Locked && <button className="btn btn-primary" onClick={() => { advanceExerciseState(ExerciseState.Results, currentExercise) }}>Show Results</button>}
            {currentExercise && currentExercise.exercise.state === ExerciseState.Results && <button className="btn btn-primary" onClick={() => { advanceExerciseState(ExerciseState.Done, currentExercise) }}>Next Exercise</button>}
          </div>
        </div>
        <div className="card mt-3">
          <ul className="list-group list-group-flush">
            {availableExercises.map((info: ExerciseInfo) => {
              const { exercise, exerciseComponent } = info
              let classes: string = 'list-group-item d-flex justify-content-between align-items-center'
              if (RunnableStates.includes(exercise.state)) {
                classes += ' font-weight-bold bg-light'
              }
              if (exercise.state === ExerciseState.Done) {
                classes += ' text-muted'
              }
              return (
              <li className={classes}>
                <span>{exerciseComponent.name}</span>
                <span className="btn btn-primary btn-sm" onClick={() => { setExerciseRunning(info) }}>Set Active</span>
              </li>
              )}
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}
