import React from 'react'
import ParticipantProps from 'components/Exercises/Participant/ParticipantProps'

export default ({ activeWorkshop, register }: ParticipantProps) => {
  return (
    <>
      <h1>Welcome! You've joined the session!</h1>
      <p className="mt-2">We're about to get started, please keep this webpage open on your device. This screen will change as we progress.</p>
    </>
  )
}