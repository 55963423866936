// import firebase from 'firebase';
import firebase from 'firebase/app'
// import 'firebase/database'
import 'firebase/firestore'
import 'firebase/auth'

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyDGt9EBFqIT3DDtWfrdfkkW9PT84Y4HY1M",
  authDomain: "blue-ocean-giving.firebaseapp.com",
  databaseURL: "https://blue-ocean-giving.firebaseio.com",
  projectId: "blue-ocean-giving",
  storageBucket: "blue-ocean-giving.appspot.com",
  messagingSenderId: "751729267487",
  appId: "1:751729267487:web:b0297638c176595183562a"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;