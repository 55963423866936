import React, { useState, useEffect } from 'react'
import firebase from 'components/firebase'
import AppState from 'components/types/AppState'
import { ExerciseState } from 'components/types/Exercise'

const SUBMISSIONS_COLLECTION: string = process.env.REACT_APP_COLLECTION_SUBMISSIONS as string

export default ({ activeExercise, activeWorkshop, activeExerciseComponent, Result, exerciseSubmissions }: AppState & { exerciseSubmissions : any[], Result : typeof React.Component }) => {
  const showResults = activeExercise && (activeExercise.state === ExerciseState.Results) && activeExerciseComponent && exerciseSubmissions.length > 0 && activeExerciseComponent.id === exerciseSubmissions[0].id && Result
	const [ prevExerciseSubmissions, setPrevExerciseSubmissions ] = useState<any[]>([] as any[])

  useEffect(() => {
    if (activeWorkshop && activeExercise && activeExercise.state === ExerciseState.Results) {
      firebase
        .firestore()
        .collection(SUBMISSIONS_COLLECTION)
        .where('workshop', '==', activeWorkshop.id)
        .get()
        .then((querySnapshot: firebase.firestore.QuerySnapshot) => {
          let nextPrevExerciseSubmissions: any[] = []
          querySnapshot.forEach(function(doc: firebase.firestore.DocumentSnapshot) {
            let submission = doc.data()
            if (submission) {
              let exerciseSubmission = submission.exercises.find((ex: { id: string, value: any }) => ex.id === '_1_09_First_Impressions_Choose_Sectors')
              if (exerciseSubmission) {
                nextPrevExerciseSubmissions.push(exerciseSubmission)
              }
            }
          })
          setPrevExerciseSubmissions([...nextPrevExerciseSubmissions])
        })
    }
  }, [activeWorkshop, activeExercise, exerciseSubmissions.length])

	return (
		<div className="col-md-12 col-lg-12 col-xl-12 text-md-center">
		  <h1 className="mb-2">Confirm Sector Choices</h1>
		  {!showResults && <>
		  	<p>After discussion, which charitable sectors do you want to support?</p>
		  </>}
      {showResults && <>
      	<h3 className="mt-4">First Choices</h3>
      	<Result exerciseSubmissions={prevExerciseSubmissions} />
      	<h3 className="mt-4">Confirmed Choices</h3>
      	<Result exerciseSubmissions={exerciseSubmissions} />
    	</>}
		</div>
	)
}