import React, { useState } from 'react'
import ParticipantProps from 'components/Exercises/Participant/ParticipantProps'
import Causes from 'components/Exercises/Causes'
import { machineName } from 'components/Exercises/format'

const TOTAL_CHOICES = 5

const Group = ({ name, causes, handleInputChange, form }: { name: string, causes: { name: string }[], handleInputChange: any, form: string[] }) => {
  const [ shown, setShown ] = useState(false)
  return (
    <div className="my-2">
      <div className="d-flex align-items-center">
        <input type="checkbox" className="xform-check-input" name={name} checked={form.indexOf(name) > -1} onChange={handleInputChange} id={machineName(name)} />
        <label className="flex-grow-1 m-0 pl-2" htmlFor={machineName(name)}>
          <h5 className="">{name}</h5>
        </label>
        <div onClick={() => setShown(!shown)} className="btn btn-primary">{shown ? 'Less' : 'More'}</div>
      </div>
      {shown && <p className="mt-1">{causes.map(({ name }) => name).join(', ')}.</p>}
    </div>
  )
}

export default ({ activeWorkshop, register, watch, exerciseComponent }: ParticipantProps) => {
  const [ form, setForm ] = useState<string[]>([])
  // const watchAll = watch()
  const numChecked = form.length

  const changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      form.push(e.target.name)
    }
    else {
      form.splice(form.indexOf(e.target.name), 1)
    }
    setForm([...form])
  }

  const move = (name: string, direction: number): void => {
    const from = form.indexOf(name)
    const to = from + direction

    if (to < 0) { return }
    if (to >= form.length) { return }

    // Move array around: https://stackoverflow.com/a/21071454
    const target = form[from]
    const increment = to < from ? -1 : 1

    for (let k = from; k !== to; k += increment) {
      form[k] = form[k + increment]
    }
    form[to] = target
    setForm([...form])
  }

  return (
    <>
      <h1>Choose up to {TOTAL_CHOICES} Causes</h1>

      {Causes.map(({ name, groups }) => <>
        <h3 className="mt-4 mb-1">{name}</h3>
        {groups.map((group) => <Group {...group} handleInputChange={changed} form={form} />)}
      </>)}

      {form.length > 0 && <>
        <div className="card xbg-light mt-4" style={{}}>
          <div className="card-header px-2">
            <h1>Prioritize Your Selections</h1>
          </div>
          <div className="card-body py-0 px-2">
            {form.map((name, index) => {
              return (
                <div className="my-2 d-flex align-items-center">
                  <input type="hidden" name={`value[${index}]`} value={name} ref={register()} />
                  <div>
                    <input type="checkbox" className="xform-check-input" name={name} checked={form.indexOf(name) > -1} onChange={changed} id={machineName(name)} />
                  </div>
                  <label className="flex-grow-1 m-0 pl-2" htmlFor={machineName(name)}>
                    <h5 className="">{index+1}. {name}</h5>
                  </label>
                  <div onClick={() => move(name, -1)} className="btn btn-primary chevron-up mr-2">Down</div>
                  <div onClick={() => move(name, 1)} className="btn btn-primary chevron-down ">Up</div>
                </div>
              )
            })}
          </div>
        </div>
      </>}

      <div className="text-center my-5">
        {numChecked > TOTAL_CHOICES && <div className="text-danger mb-2">Only up to {TOTAL_CHOICES} selections are allowed.</div>}
        <input disabled={numChecked > TOTAL_CHOICES} className="btn btn-primary" type="submit" value="Submit my Answers" />
        <p className="text-muted mt-2">Answers cannot be changed after submitting!</p>
      </div>
    </>
  )
}