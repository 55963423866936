import React, { useState } from 'react'
import Workshop from 'components/types/Workshop'

interface CounterState {
   id: string
   value: number
   reveal: boolean
}

export default ({ exerciseSubmissions,
  activeWorkshop,
  clickable = false,
  revealed = true,
  fireworks = false,
}: {
  exerciseSubmissions : { id: string, value: string[] }[],
  activeWorkshop: Workshop,
  clickable?: boolean,
  revealed?: boolean,
  fireworks?: boolean,
}) => {
  const [ counters, setCounters ] = useState<CounterState[]>([])
  if (!exerciseSubmissions[0]) {
    return null
  }
  const length = exerciseSubmissions[0].value.length
  let SORTED_SCORES: number[] =  []
  for (var i = 0; i < length; i++) {
    SORTED_SCORES.push(length - i)
  }

  const charities = activeWorkshop.charities.map((charity, index) => { return {
    ...charity,
    fakeName: `Charity ${index+1}`,
  }})

  const toggleReveal = (index: number): void => {
    counters[index].reveal = !counters[index].reveal
    setCounters([...counters])
  }

  if (counters.length !== exerciseSubmissions[0].value.length) {
    let nextCounters: CounterState[] = []
    exerciseSubmissions.forEach((submission, index) => {
      submission.value.forEach((value, valueIndex) => {
        let index = nextCounters.findIndex(count => count.id === value)
        if (index === -1) {
          index = nextCounters.length
          nextCounters.push({ id: value, value: 0, reveal: revealed })
        }
        if (submission.value[valueIndex]) {
          nextCounters[index].value += SORTED_SCORES[valueIndex]
        }
      })
    })
    setCounters(nextCounters)
  }

  return (
    <>
      {counters.sort((a, b) => b.value - a.value).map(({ id, value, reveal }, index) => {
        const charity = charities.find(charity => charity.name === id)
        if (!charity) {
          return <div>Unable to match data.</div>
        }
        return <>
          <div className="card my-3 border-bottom xpb-2 text-left" style={{position: 'relative'}}>
            <div onClick={() => clickable && toggleReveal(index)} className="card-header py-2 pr-2 pl-3" style={{position: 'sticky', top: '79px', background: '#efefef', cursor: clickable ? 'pointer' : 'initial', zIndex: 1 }}>
              <h4 className="xcol-8"><strong>{index+1}. {reveal ? charity.name : charity.fakeName}:</strong> {value}pts</h4>
              {reveal && fireworks && <div className="pyro"><div className="before"></div></div>}
            </div>
            <div className="card-body pl-3 pr-3 py-2">
              <p className="hyphens">{charity.description}</p>
              <div className="row">
                <div className="col-6">
                  <strong>Causes:</strong>
                  <p className="mb-0">{charity.causes}</p>
                </div>
                <div className="col-6">
                  <strong>Revenue:</strong>
                  <p className="mb-0">{charity.revenue}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      })}
    </>
  )
}