import React from 'react'
import ParticipantProps from 'components/Exercises/Participant/ParticipantProps'

export default ({ activeWorkshop, register }: ParticipantProps) => {
  return (
    <>
      <h1>Thank You!</h1>
      <p className="mt-2">Do you like sharing your opinion? We have a 4-question survey about your experience today to help us improve.</p>
      <a className="btn btn-primary" href="https://denimandsteelint.typeform.com/to/d24n6d">Take the Survey</a>
    </>
  )
}