export enum ExerciseState {
  Ready = 'READY',
  Running = 'RUNNING',
  Answerable = 'ANSWERABLE',
  Locked = 'LOCKED',
  Results = 'RESULTS',
  Done = 'DONE',
}

export const RunnableStates = [ExerciseState.Running, ExerciseState.Answerable, ExerciseState.Locked, ExerciseState.Results]

export default interface Exercise {
  id: string;
  state: ExerciseState;
  // session: string;
}