import React from 'react'
import { percent } from 'components/Exercises/format'

export default ({ exerciseSubmissions }: { exerciseSubmissions : { id: string, value: { [key: string]: boolean } }[] }) => {
  let counters : { id: string, value: number }[] = []
  exerciseSubmissions.forEach((submission) => {
    Object.keys(submission.value).forEach((key) => {
      let index = counters.findIndex((count) => count.id === key)
      if (index === -1) {
        index = counters.length
        counters.push({ id: key, value: 0 })
      }
      if (submission.value[key]) {
        counters[index].value += 1
      }
    })
  })
  return (
    <div className="row mt-4 text-left">
      {counters.sort((a, b) => b.value - a.value).map(({ id, value }) => <>
        <div className="offset-2 col-3 mb-3 text-right">
          <h4><strong>{percent(value, exerciseSubmissions.length)} ({value}): </strong></h4>
        </div>
        <div className="col-5 mb-3">
          <h4><span style={{ whiteSpace: 'nowrap'}}>{id}</span></h4>
        </div>
      </>)}
    </div>
  )
}
